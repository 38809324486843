import { useState, Fragment } from "react";
import Footer from "../component/layout/footer";
import HeaderTwo from "../component/layout/headertwo";
import PageHeader from "../component/layout/pageheader";
import GoogleMap from "../component/section/googlemap";


const infotitle = "Contact Info";
const infosubtitle = "Let us know your opinions. Also you can write us if you have any questions.";
const contacttitle = "Feedback.";
const contactdesc = "Let us know your opinions. Also you can write us if you have any questions..";


let infoListContent = [
    {
        imgUrl: 'assets/images/contact/icon/01.png',
        imgAlt: 'Contact Info Thumb',
        title: 'Address',
        desc: 'Kreuzstrasse 24, 8008 Zürich, Schweiz',
    },
    {
        imgUrl: 'assets/images/contact/icon/02.png',
        imgAlt: 'Contact Info Thumb',
        title: 'Phone number',
        desc: '+41 43 205 22 90',
    },
    {
        imgUrl: 'assets/images/contact/icon/03.png',
        imgAlt: 'Contact Info Thumb',
        title: 'Send Email',
        desc: 'welcome@goldstueck.ch',
    },
]

function ContactUs() {
    const [contactName, setContactName] = useState('');
    const [contactEmail, setContactEmail] = useState('');
    const [contactSubject, setContactSubject] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [contactMessage, setContactMessage] = useState('');

        return (
            <Fragment>
                <HeaderTwo />
                <PageHeader title={'Contact Us'} curPage={'Contact'} />
                <div className="info-section padding-top padding-bottom">
                    <div className="container">
                        <div className="section__header style-2 text-center">
                            <h2>{infotitle}</h2>
                            <p>{infosubtitle}</p>
                        </div>
                        <div className="section-wrapper">
                            <div className="row justify-content-center g-4">
                                {infoListContent.map((val, i) => (
                                    <div className="col-lg-4 col-sm-6 col-12" key={i}>
                                        <div className="contact-item text-center">
                                            <div className="contact-thumb mb-4">
                                                <img 
                                                    src={`${val.imgUrl}`} 
                                                    alt={`${val.imgAlt}`} 
                                                />
                                            </div>
                                            <div className="contact-content">
                                                <h6 className="title">{val.title}</h6>
                                                <p>{val.desc}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contact-section bg-white">
                    <div className="contact-top padding-top padding-bottom">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12 col-lg-9">
                                    <div className="contact-form-wrapper text-center">
                                        <h2>{contacttitle}</h2>
                                        <p className="mb-5">{contactdesc}</p>
                                        <form className="contact-form" action="contact.php" id="contact-form" method="POST">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    name="name"
                                                    id="item01"
                                                    value={contactName}
                                                    onChange={(e)=>{setContactName(e.target.value)}}
                                                    placeholder="Your Name *"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    name="email"
                                                    id="item02"
                                                    value={contactEmail}
                                                    onChange={(e)=>{setContactEmail(e.target.value)}}
                                                    placeholder="Your Email *"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    name="number"
                                                    id="item04"
                                                    value={contactNumber}
                                                    onChange={(e)=>{setContactNumber(e.target.value)}}
                                                    placeholder="Mobile Number *"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    name="subject"
                                                    id="item03"
                                                    value={contactSubject}
                                                    onChange={(e)=>{setContactSubject(e.target.value)}}
                                                    placeholder="Your Subject *"
                                                />
                                            </div>
                                            <div className="form-group w-100">
                                                <textarea 
                                                    rows="8" 
                                                    type="text"
                                                    id="item05"
                                                    name="message"
                                                    value={contactMessage}
                                                    onChange={(e)=>{setContactMessage(e.target.value)}}
                                                    placeholder="Your Message"
                                                ></textarea>
                                            </div>
                                            <div className="form-group w-100 text-center">
                                                <button className="default-btn reverse" type="submit"><span>Send our Message</span></button>
                                            </div>
                                        </form>
                                        <p className="form-message"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="contact-bottom">
                        <div className="contac-bottom">
                            <div className="row justify-content-center g-0">
                                <div className="col-12">
                                    <div className="location-map">
                                        <GoogleMap />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </Fragment>
        );
}
 
export default ContactUs;