import { Component } from "react";
import { Link } from "react-router-dom";

const title = "Healthcare Workers in Your Area";
const desc = "Bringing compassionate and dedicated professionals closer to you, ensuring reliable and personalized support for your daily healthcare needs.";
const btnText = "Search Near You";


let MeetSectionContentListOne = [
    {
        imgUrl: 'assets/images/meet/01.jpg',
        imgAlt: 'Zürich',
        imgIconUrl: 'assets/images/meet/icon/01.jpg',
        imgIconAlt: 'Zürich',
        title: 'Zürich',
    },
]

let MeetSectionContentListTwo = [
    {
        imgUrl: 'assets/images/meet/02.jpg',
        imgAlt: 'Basel',
        imgIconUrl: 'assets/images/meet/icon/02.jpg',
        imgIconAlt: 'Basel',
        title: 'Basel',
    },
    {
        imgUrl: 'assets/images/meet/03.jpg',
        imgAlt: 'Lausanne',
        imgIconUrl: 'assets/images/meet/icon/03.jpg',
        imgIconAlt: 'Lausanne',
        title: 'Lausanne',
    },
    {
        imgUrl: 'assets/images/meet/04.jpg',
        imgAlt: 'Bern',
        imgIconUrl: 'assets/images/meet/icon/04.jpg',
        imgIconAlt: 'Bern',
        title: 'Bern',
    },
    {
        imgUrl: 'assets/images/meet/05.jpg',
        imgAlt: 'Winterthur',
        imgIconUrl: 'assets/images/meet/icon/05.jpg',
        imgIconAlt: 'Winterthur',
        title: 'Winterthur',
    },
]

let MeetSectionContentListThree = [
    {
        imgUrl: 'assets/images/meet/06.jpg',
        imgAlt: 'Geneva',
        imgIconUrl: 'assets/images/meet/icon/06.jpg',
        imgIconAlt: 'Geneva',
        title: 'Geneva',
    },
]

function MeetSection() {
        return (
            <div className="meet padding-top padding-bottom">
                <div className="container">
                    <div className="section__header style-2 text-center wow fadeInUp" data-wow-duration="1.5s">
                        <h2>{title}</h2>
                        <p>{desc}</p>
                    </div>
                    <div className="section__wrapper">
                        <div className="row g-4 justify-content-center wow fadeInUp" data-wow-duration="1.5s">
                            {MeetSectionContentListOne.map((val, i) => (
                                <div className="col-lg-6 col-12" key={i}>
                                    <div className="meet__item">
                                        <div className="meet__inner">
                                            <div className="meet__thumb">
                                                <Link to="/members"><img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} /></Link>
                                            </div>
                                            <div className="meet__content">
                                                <Link to="/members"><h4>{val.title}</h4></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}

                            {MeetSectionContentListTwo.map((val, i) => (
                                <div className="col-lg-3 col-sm-6 col-12" key={i}>
                                    <div className="meet__item">
                                        <div className="meet__inner">
                                            <div className="meet__thumb">
                                                <Link to="/members"><img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} /></Link>
                                            </div>
                                            <div className="meet__content">
                                                <Link to="/members"><h4>{val.title}</h4></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}

                            {MeetSectionContentListThree.map((val, i) => (
                                <div className="col-lg-6 col-12" key={i}>
                                    <div className="meet__item">
                                        <div className="meet__inner">
                                            <div className="meet__thumb">
                                                <Link to="/members"><img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} /></Link>
                                            </div>
                                            <div className="meet__content">
                                                <Link to="/members"><h4>{val.title}</h4></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="text-center mt-5 wow fadeInUp" data-wow-duration="1.5s">
                            <Link to="/members" className="default-btn"><span>{btnText}</span></Link>
                        </div>
                    </div>
                </div>
            </div>
        );
}
 
export default MeetSection;