import React, {Component, Fragment, useState} from "react";
import {Link} from "react-router-dom";
import Footer from "../component/layout/footer";
import HeaderTwo from "../component/layout/headertwo";
import PageHeader from "../component/layout/pageheader";
import AboutSectionFour from "../component/section/aboutfour";
import Pagination from "../component/section/pagination";
import SelectProduct from "../component/select/selectproduct";
import Select from "react-select";
import Profiles from "../component/custom/profiles";

const genderOptions = [
  {value: 'all', label: 'All genders'},
  {value: 'male', label: 'Male'},
  {value: 'female', label: 'Female'},
]

const regionOptions = [
  {value: 'Aargau', label: 'Aargau'},
  {value: 'Appenzell Ausserrhoden', label: 'Appenzell Ausserrhoden'},
  {value: 'Appenzell Innerrhoden', label: 'Appenzell Innerrhoden'},
  {value: 'Basel-Landschaft', label: 'Basel-Landschaft'},
  {value: 'Basel-Stadt', label: 'Basel-Stadt'},
  {value: 'Bern', label: 'Bern'},
  {value: 'Fribourg', label: 'Fribourg'},
  {value: 'Geneva', label: 'Geneva'},
  {value: 'Glarus', label: 'Glarus'},
  {value: 'Graubünden', label: 'Graubünden'},
  {value: 'Jura', label: 'Jura'},
  {value: 'Lucerne', label: 'Lucerne'},
  {value: 'Neuchâtel', label: 'Neuchâtel'},
  {value: 'Nidwalden', label: 'Nidwalden'},
  {value: 'Obwalden', label: 'Obwalden'},
  {value: 'Schaffhausen', label: 'Schaffhausen'},
  {value: 'Schwyz', label: 'Schwyz'},
  {value: 'Solothurn', label: 'Solothurn'},
  {value: 'St. Gallen', label: 'St. Gallen'},
  {value: 'Thurgau', label: 'Thurgau'},
  {value: 'Ticino', label: 'Ticino'},
  {value: 'Uri', label: 'Uri'},
  {value: 'Valais', label: 'Valais'},
  {value: 'Vaud', label: 'Vaud'},
  {value: 'Zug', label: 'Zug'},
  {value: 'Zurich', label: 'Zurich'}
]

const languageOptions = [
  {value: 'German', label: 'German'},
  {value: 'English', label: 'English'},
  {value: 'French', label: 'French'},
  {value: 'Italian', label: 'Italian'}
]

const allocationOptions = [
  {value: 'stay-in', label: 'Stay in (Permanent)'},
  {value: 'casual', label: 'Casual'},
]

const skillOptions = [
  {value: 'Compassion and empathy', label: 'Compassion and empathy'},
  {value: 'Communication', label: 'Communication'},
  {value: 'Patience', label: 'Patience'},
  {value: 'Adaptability', label: 'Adaptability'},
  {value: 'Dependability and reliability', label: 'Dependability and reliability'},
  {value: 'Attention to detail', label: 'Attention to detail'},
  {value: 'Time management', label: 'Time management'},
  {value: 'Problem-solving', label: 'Problem-solving'},
  {value: 'Physical stamina', label: 'Physical stamina'},
  {value: 'Self-care', label: 'Self-care'}
]

const qualOptions = [
  {value: 'CPR and First Aid certification', label: 'CPR and First Aid certification'},
  {value: 'CNA certification', label: 'CNA certification'},
  {value: 'Caregiving experience', label: 'Caregiving experience'},
  {value: 'Physiotherapist', label: 'Physiotherapist'},
  {value: 'Valid driver\'s license', label: 'Valid driver\'s license'},
]

function MembersPage() {
  const modalview = () => {
    document.querySelector('.modal').classList.toggle('show')
    document.querySelector('body').classList.toggle('overlay')
  }

  const [filter, setFilter] = useState({
    driving: '',
    hasCar: ''
  })

  return (
    <Fragment>
      <HeaderTwo isLoggedIn/>
      <PageHeader title={'Goldstück Caregivers'} curPage={'Caregivers'}/>
      <div className="member member--style2 padding-bottom" style={{paddingTop: '66px'}}>
        <div className="container">
          <div className="section__wrapper">
            <div className="member__info mb-4">
              <div className="member__info--left">
                <div className="member__info--filter">
                  <div className="default-btn" onClick={modalview}><span>Filter Your Search <i
                    className="fa-solid fa-sliders"></i></span></div>
                </div>
                <div className="member__info--count">
                  <div className="default-btn"><span>Results</span></div>
                  <p>20365587</p>
                </div>
              </div>
              <div className="member__info--right">
                <div className="member__info--customselect right w-100">
                  <div className="default-btn"><span>Order By:</span></div>
                  <div className="banner__inputlist">
                    <SelectProduct select={'Newest'}/>
                  </div>
                </div>
              </div>
            </div>
              <Profiles/>
            <div className="member__pagination mt-4">
              <div className="member__pagination--left">
                <p>Viewing 1 - 20 of 12,345 Members</p>
              </div>
              <div className="member__pagination--right">
                <Pagination/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="exampleModal">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Filter your search</h5>
              <button type="button" className="btn-close" onClick={modalview}></button>
            </div>
            <div className="modal-body">
              <form action="#">
                <div className="banner__list">
                  <div className="row align-items-baseline row-cols-1 row-cols-lg-2">
                    <div className="col">
                      <label>Allocation</label>
                      <Select options={allocationOptions} styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          padding: '10px',
                          marginBottom: '20px',
                          border: '1px solid #2133661a'
                        }),
                      }} name='allocation'/>
                    </div>
                    <div className="col">
                      <label>Gender</label>
                      <Select options={genderOptions} styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          padding: '10px',
                          marginBottom: '20px',
                          border: '1px solid #2133661a'
                        }),
                      }} name='gender'/>
                    </div>
                    <div className="col">
                      <label>Region</label>
                      <div className="row">
                        <div className="col-12">
                          <Select options={regionOptions} isMulti classNamePrefix="react-select" styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              padding: '10px',
                              marginBottom: '20px',
                              border: '1px solid #2133661a'
                            }),
                          }} name='region'/>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <label>Language</label>
                      <div className="row">
                        <div className="col-12">
                          <Select options={languageOptions} isMulti classNamePrefix="react-select" styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              padding: '10px',
                              marginBottom: '20px',
                              border: '1px solid #2133661a'
                            }),
                          }} name='language'/>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <label>Skills</label>
                      <div className="row">
                        <div className="col-12">
                          <Select options={skillOptions} isMulti classNamePrefix="react-select" styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              padding: '10px',
                              marginBottom: '20px',
                              border: '1px solid #2133661a'
                            }),
                          }} name='skills'/>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <label>Qualifications</label>
                      <div className="row">
                        <div className="col-12">
                          <Select options={qualOptions} isMulti classNamePrefix="react-select" styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              padding: '10px',
                              marginBottom: '20px',
                              border: '1px solid #2133661a'
                            }),
                          }} name='quals'/>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <label>Driving license</label>
                      <div className="row">
                        <div className="col-6">
                          <label className="input-list">
                            <input type="radio" value="yes" checked={filter.driving === 'yes'} name="driving" onChange={(e) => setFilter({...filter, driving: e.target.value})}/>
                            <span>Yes</span>
                          </label>
                        </div>
                        <div className="col-6">
                          <label className="input-list">
                            <input type="radio" value="no" checked={filter.driving === 'no'} name="driving" onChange={(e) => setFilter({...filter, driving: e.target.value})}/>
                            <span>No</span>
                          </label>
                        </div>
                      </div>
                      {filter.driving === 'yes' && <>
                        <div className="row">
                          <div className="col-6">
                            <label className="input-list">
                              <input type="radio" value="yes" checked={filter.hasCar === 'yes'} name="hasCar" onChange={(e) => setFilter({...filter, hasCar: e.target.value})}/>
                              <span>Owns a car</span>
                            </label>
                          </div>
                          <div className="col-6">
                            <label className="input-list">
                              <input type="radio" value="no" checked={filter.hasCar === 'no'} name="hasCar" onChange={(e) => setFilter({...filter, hasCar: e.target.value})}/>
                              <span>Has no car</span>
                            </label>
                          </div>
                        </div>
                      </>}
                    </div>
                    <div className="col">
                      <label>Smoking</label>
                      <div className="row">
                        <div className="col-6">
                          <label className="input-list">
                            <input type="radio" name="smoking"/>
                            <span>Yes</span>
                          </label>
                        </div>
                        <div className="col-6">
                          <label className="input-list">
                            <input type="radio" name="smoking"/>
                            <span>No</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <button type="submit" className="default-btn reverse d-block"><span>Apply Filter</span></button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </Fragment>
  );
}

export default MembersPage;