import React, {Fragment, useContext, useEffect, useState} from "react";
import Footer from "../../component/layout/footer";
import HeaderTwo from "../../component/layout/headertwo";
import PageHeader from "../../component/layout/pageheader";
import "yet-another-react-lightbox/styles.css";
import Dashboard from "./dashboard";
import Profiles from "../../component/custom/profiles";
import Subscription from "./subscription";
import Account from "./account";
import Messaging from "./messaging";
import {UserTypeContext} from "../../App";
import {Link} from "react-router-dom";
import MyProfile from "./myProfile";
import Orders from "./orders";
import CompanyProfiles from "./companyProfiles";

const name = "Emily Jenkins";
const activety = "Active 3 Days Ago";
const desc = "Challenges are whats make lifes interesting and overcoming them is what makes life meaningful";

export default function MenuPage() {
  window.addEventListener('scroll', function() {
    var value = window.scrollY;
    if (value > 200) {
      document.querySelector('.left-menu').classList.add(['menu-fixed'])
    }else{
      document.querySelector('.left-menu').classList.remove(['menu-fixed'])
    }
  });

  const CGCpages = [
    {name: 'dashboard', icon: 'fa-chart-line'},
    {name: 'profiles', icon: 'fa-users'},
    {name: 'subscription', icon: 'fa-cart-shopping'},
    {name: 'account', icon: 'fa-circle-user'},
    {name: 'messaging', icon: 'fa-message'},
  ];
  const CGpages = [
    {name: 'dashboard', icon: 'fa-chart-line'},
    {name: 'my profile', icon: 'fa-user'},
    {name: 'subscription', icon: 'fa-cart-shopping'},
    {name: 'account', icon: 'fa-circle-user'},
    {name: 'messaging', icon: 'fa-message'},
  ];
  const CRpages = [
    {name: 'dashboard', icon: 'fa-chart-line'},
    {name: 'orders', icon: 'fa-tags'},
    {name: 'subscription', icon: 'fa-cart-shopping'},
    {name: 'account', icon: 'fa-circle-user'},
    {name: 'messaging', icon: 'fa-message'},
  ];
  const [pages, setPages] = useState(CGCpages);
  const [activePage, setActivePage] = useState(pages[0])
  const userType = useContext(UserTypeContext);

  useEffect(() => {
    if (userType === 'company') {
      setPages(CGCpages);
    }
    if (userType === 'giver') {
      setPages(CGpages);
    }
    if (userType === 'receiver') {
      setPages(CRpages);
    }
  }, [userType]);

  return (
    <Fragment>
      <HeaderTwo isLoggedIn />
      {/*<PageHeader title={'Caregiver Profile'} curPage={'Caregiver Profile'} />*/}
      <div id="menu-page" className="group group--single padding-bottom">
        <div className="group__bottom">
          <div className="container">
            <div className="row g-4">
              <div className="col-xl-2 order-0">
                <ul className="left-menu d-flex flex-xl-column">
                  {pages.map(({name, icon}, index) => (
                    <li key={index} className={name === activePage.name ? 'active' : ''}>
                      <a href="#" onClick={() => setActivePage(pages[index])}>
                        <i className={`fa-solid ${icon}`} />{name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-xl-10 order-1">
                <div className="account-content">
                  {activePage.name === 'dashboard' && <Dashboard />}
                  {activePage.name === 'profiles' && <CompanyProfiles />}
                  {activePage.name === 'subscription' && <Subscription />}
                  {activePage.name === 'account' && <Account />}
                  {activePage.name === 'messaging' && <Messaging />}
                  {activePage.name === 'my profile' && <MyProfile />}
                  {activePage.name === 'orders' && <Orders />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}