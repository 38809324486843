import { Component } from "react";

const title = "Goldstück Super Powers";
const subtitle = "Unleash the magic of simplicity and precision in your healthcare journey with our intuitive platform—where tailored matches, secure messaging, personal assistance, and a user-friendly experience seamlessly come together to empower your every step.";

let AboutContentList = [
    {
        imgUrl: 'assets/images/about/01.jpg',
        imgAlt: 'Tailored Matches',
        title: 'Tailored Matches',
        desc: 'Find the perfect healthcare match with our advanced algorithm, customized to your needs.',
    },
    {
        imgUrl: 'assets/images/about/02.jpg',
        imgAlt: 'Secure Messaging Hub',
        title: 'Secure Messaging Hub',
        desc: 'Connect securely through our messaging feature, ensuring reliable communication between users and healthcare workers.',
    },
    {
        imgUrl: 'assets/images/about/03.jpg',
        imgAlt: 'Personal Healthcare Assistance',
        title: 'Personal Healthcare Assistance',
        desc: 'Get ongoing, daily support from healthcare workers without the need for appointment bookings.',
    },
    {
        imgUrl: 'assets/images/about/04.jpg',
        imgAlt: 'Trustworthy Feedback',
        title: 'Trustworthy Feedback',
        desc: 'Build trust with our review system, allowing users to share and rate their experiences with healthcare workers.',
    },
]

function AboutSection() {
        return (
            <div className="about padding-top padding-bottom">
                <div className="container">
                    <div className="section__header style-2 text-center wow fadeInUp" data-wow-duration="1.5s">
                        <h2>{title}</h2>
                        <p>{subtitle}</p>
                    </div>
                    <div className="section__wrapper">
                        <div className="row g-4 justify-content-center row-cols-xl-4 row-cols-lg-3 row-cols-sm-2 row-cols-1">
                            {AboutContentList.map((val, i) => (
                                <div className="col wow fadeInUp" data-wow-duration="1.5s" key={i}>
                                    <div className="about__item text-center">
                                        <div className="about__inner">
                                            <div className="about__thumb">
                                                <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                            </div>
                                            <div className="about__content">
                                                <h4>{val.title}</h4>
                                                <p>{val.desc}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
}

export default AboutSection;