import React, {Fragment, useState} from "react";
import Footer from "../component/layout/footer";
import HeaderTwo from "../component/layout/headertwo";
import PageHeader from "../component/layout/pageheader";
import ActiveMember from "../component/sidebar/member";
import ModalSearch from "../component/sidebar/modalsearch";
import {Link} from "react-router-dom";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Select from "react-select";

const name = "Emily Jenkins";
const activety = "Active 3 Days Ago";
const desc = "Challenges are whats make lifes interesting and overcoming them is what makes life meaningful";

const profileImages = [
    { src: 'assets/images/member/profile/01.jpg', width: 700, height: 700, imageFit: 'contain'},
    { src: 'assets/images/member/profile/02.jpg', width: 700, height: 700, imageFit: 'contain'},
    { src: 'assets/images/member/profile/03.jpg', width: 700, height: 700, imageFit: 'contain'},
    { src: 'assets/images/member/profile/04.jpg', width: 700, height: 700, imageFit: 'contain'},
    { src: 'assets/images/member/profile/05.jpg', width: 700, height: 700, imageFit: 'contain'},
    { src: 'assets/images/member/profile/06.jpg', width: 700, height: 700, imageFit: 'contain'},
]

const allocationOptions = [
    { value: 'stay-in', label: 'Stay in (Permanent)' },
    { value: 'casual', label: 'Casual' },
]

function MemberDetails() {
    const [picIndex, setPicIndex] = useState(-1);

    const [user, setUser] = useState({
        name: 'Emily Jenkins',
        age: 28,
        date: '27-02-1996',
        intro: 'I\'ve been passionate about healthcare for as long as I can remember. Helping others navigate their health journeys and providing support along the way is not just a job for me; it\'s a calling. With 7 years of experience in the medical field, I\'ve had the privilege of working with diverse individuals and families, each with their own unique health needs and stories.\n' +
          'My mission as a healthcare provider is simple: to make a positive difference in the lives of my patients. Whether it\'s addressing acute concerns, managing chronic conditions, or simply providing guidance on living a healthier lifestyle, I\'m here to empower you to take control of your health and well-being.',
        service: {
            location: "Private",
            allocation: allocationOptions[0],
            pricing: 24
        }
    });

        return (
            <Fragment>
                <HeaderTwo isLoggedIn/>
                <PageHeader title={'Caregiver Profile'} curPage={'Caregiver Profile'} />
                <div className="group group--single padding-bottom">
                    <div className="group__bottom">
                        <div className="container">
                            <div className="row g-4">
                                <div className="col-xl-6 order-1 order-xl-1">
                                    <div className="group__bottom--left">
                                        <div className="tab-content" id="myTabContent">
                                            <div className="tab-pane show active fade" id="gt1" role="tabpanel" aria-labelledby="gt1-tab">
                                                <div className="info">
                                                    <form className="profileEditForm" action="#">
                                                    <div className="info-card mb-4">
                                                        <div className="info-card-title">
                                                            <h6>Base Info</h6>
                                                        </div>
                                                        <div className="info-card-content">
                                                                <ul className="info-list">
                                                                    <li>
                                                                        <p className="info-name">Name</p>
                                                                        <p className="info-details">{user.name}</p>
                                                                    </li>
                                                                    <li>
                                                                        <p className="info-name">Age</p>
                                                                        <p className="info-details">{user.age}</p>
                                                                    </li>
                                                                    <li>
                                                                        <p className="info-name">Date of Birth</p>
                                                                        <p className="info-details">{user.date}</p>
                                                                    </li>
                                                                </ul>
                                                        </div>
                                                    </div>
                                                    <div className="info-card mb-4">
                                                        <div className="info-card-title">
                                                            <h6>Qualifications</h6>
                                                        </div>
                                                        <div className="info-card-content">
                                                            <ul className="info-list info-list--quals">
                                                                <li>
                                                                    <div className="qual-item">
                                                                        <img src="assets/images/institution/goldstueck.png" alt="Goldstueck logo"/>
                                                                        <div className="d-flex flex-column">
                                                                            <p className="name">Goldstück AG</p>
                                                                            <p className="desc">CPR and First Aid certification</p>
                                                                            <p className="year">2021-2024</p>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="qual-item">
                                                                        <img src="assets/images/institution/placeholder.png" alt="Uni logo"/>
                                                                        <div className="d-flex flex-column">
                                                                            <p className="name">Horizon State University</p>
                                                                            <p className="desc">CNA certification</p>
                                                                            <p className="year">2018-2021</p>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="info-card mb-4">
                                                        <div className="info-card-title">
                                                            <h6>Introduction</h6>
                                                        </div>
                                                        <div className="info-card-content">
                                                            <p>{user.intro}</p>
                                                        </div>
                                                    </div>
                                                    <div className="info-card mb-4">
                                                        <div className="info-card-title">
                                                            <h6>Service details</h6>
                                                        </div>
                                                        <div className="info-card-content">
                                                            <ul className="info-list">
                                                                <li>
                                                                    <p className="info-name">Location</p>
                                                                    <p className="info-details">Private</p>
                                                                </li>
                                                                <li>
                                                                    <p className="info-name">Allocation</p>
                                                                    <p className="info-details">{user.service.allocation.label}</p>
                                                                </li>
                                                                <li>
                                                                    <p className="info-name">Languages</p>
                                                                    <p className="info-details">
                                                                        <span className="d-flex gap-2 flex-column flex-md-row">
                                                                            <span className="pill">German - Fluent</span>
                                                                            <span className="pill">English - Intermediate</span>
                                                                        </span>
                                                                    </p>
                                                                </li>
                                                                <li>
                                                                    <p className="info-name">Pricing, per hour</p>
                                                                    <p className="info-details">CHF 24</p>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="info-card mb-4">
                                                        <div className="info-card-title">
                                                            <h6>Skills</h6>
                                                        </div>
                                                        <div className="info-card-content">
                                                            <div className="d-flex gap-2 flex-column flex-md-row">
                                                                <div className="pill">Communication</div>
                                                                <div className="pill">Patience</div>
                                                                <div className="pill">Attention to detail</div>
                                                                <div className="pill">Dependability and reliability</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 order-0 order-xl-0">
                                    <div className="group__bottom--center">
                                        <div className="story__item style2">
                                            <div className="story__inner">
                                                <div className="story__thumb position-relative">
                                                    <img src="assets/images/member/profile/profile.jpg" alt="Profile" />
                                                </div>
                                                <div className="story__content px-0 pb-0">
                                                    <h4>{name}</h4>
                                                    <div className="story__content--content mb-2 pb-3">
                                                        <p><i className="fa-solid fa-clock"></i> {activety}</p>
                                                    </div>
                                                    <p className="mb-2">{desc}</p>
                                                    <div className="story__content--author mt-3 pb-2">
                                                        <h6 className="d-block w-100 mb-3">{name} Photos</h6>
                                                        <div className="row g-2">
                                                            <PhotoAlbum
                                                              photos={profileImages}
                                                              layout="rows"
                                                              onClick={({index: current}) => setPicIndex(current)}
                                                              targetRowHeight={(containerWidth) => {
                                                                  if (containerWidth < 280) return  72;
                                                                  if (containerWidth > 280 && containerWidth < 380) return  87;
                                                                  if (containerWidth > 380 && containerWidth < 670) return  157;
                                                                  if (containerWidth > 670 && containerWidth < 900) return  217;
                                                                  return 297;
                                                              }}
                                                            />
                                                            <Lightbox index={picIndex} slides={profileImages} open={picIndex >= 0} close={() => setPicIndex(-1)}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 order-2 order-xl-2">
                                    <div className="group__bottom--right">
                                        <div className="modal-content border-0 mb-4">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="exampleModalLabel1">Book this caregiver</h5>
                                            </div>
                                            <div className="modal-body"><Link to="/shop-cart" className="default-btn d-block w-100 text-center"><span>Book</span></Link></div>
                                        </div>
                                        <ModalSearch />
                                        <ActiveMember />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        );
}
 
export default MemberDetails;