import { Component } from "react";

const title = "How Does it Work";

let WorkSectionContentListOne = [
    {
        imgUrl: 'assets/images/work/06.png',
        imgAlt: 'Register',
        title: 'Register on the Platform',
        desc: 'Begin your journey by creating a personalized account on our platform. It\'s quick and easy, allowing you to unlock a world of healthcare assistance tailored to your requirements.',
    },
]
let WorkSectionContentListTwo = [
    {
        imgUrl: 'assets/images/work/05.png',
        imgAlt: 'Search',
        title: 'Search by your criteria',
        desc: 'Define your preferences with precision. Set search criteria such as location, skills, and availability to ensure you receive personalized matches that align perfectly with your healthcare needs.',
    },
]
let WorkSectionContentListThree = [
    {
        imgUrl: 'assets/images/work/07.png',
        imgAlt: 'Inspect',
        title: 'Inspect Matching Profiles',
        desc: 'Explore profiles of healthcare workers meticulously matched to your criteria. Review qualifications, experience, and user ratings to make an informed decision, putting you in control of your healthcare journey.',
    },
]
let WorkSectionContentListFour = [
    {
        imgUrl: 'assets/images/work/08.png',
        imgAlt: 'Booking',
        title: 'Book Healthcare Worker',
        desc: 'Connect effortlessly by initiating a conversation with the chosen healthcare worker. Once you\'re confident in your choice, take the next step—book your healthcare worker for ongoing, daily assistance in your healthcare journey.',
    },
]

function WorkSection() {
        return (
            <div className="work padding-top padding-bottom bg_img" style={{backgroundImage: "url(/assets/images/bg-img/01.jpg)"}}>
                <div className="container">
                    <div className="section__header text-center wow fadeInUp" data-wow-duration="1.5s">
                        <h2>{title}​</h2>
                    </div>
                    <div className="section__wrapper wow fadeInUp" data-wow-duration="1.5s">
                        <div className="d-xl-flex align-items-start work__area">
                            <div className="nav flex-xl-column nav-pills me-xl-4 work__tablist justify-content-start" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                <button className="nav-link active" id="work__tab1-tab" data-bs-toggle="pill" data-bs-target="#work__tab1" type="button" role="tab" aria-controls="work__tab1" aria-selected="true"><img src="assets/images/work/01.png" alt="Register" className="me-3" /><span>Register on the Platform</span></button>
                                <button className="nav-link" id="work__tab2-tab" data-bs-toggle="pill" data-bs-target="#work__tab2" type="button" role="tab" aria-controls="work__tab2" aria-selected="false"><img src="assets/images/work/02.png" alt="Search" className="me-3" /><span>Search by your criteria</span></button>
                                <button className="nav-link" id="work__tab3-tab" data-bs-toggle="pill" data-bs-target="#work__tab3" type="button" role="tab" aria-controls="work__tab3" aria-selected="false"><img src="assets/images/work/03.png" alt="Inspect" className="me-3" /><span>Inspect Matching Profiles</span></button>
                                <button className="nav-link" id="work__tab4-tab" data-bs-toggle="pill" data-bs-target="#work__tab4" type="button" role="tab" aria-controls="work__tab4" aria-selected="false"><img src="assets/images/work/04.png" alt="Booking" className="me-3" /><span>Book Healthcare Worker</span></button>
                            </div>
                            <div className="tab-content work__tabcontent" id="v-pills-tabContent">
                                <div className="tab-pane fade show active" id="work__tab1" role="tabpanel" aria-labelledby="work__tab1-tab">
                                    {WorkSectionContentListOne.map((val, i) => (
                                        <div className="work__item" key={i}>
                                            <div className="work__inner">
                                                <div className="work__thumb">
                                                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} style={{ height: '244px', width: 'auto', margin: '0 auto' }} />
                                                </div>
                                                <div className="work__content">
                                                    <h3>{val.title}</h3>
                                                    <p>{val.desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="tab-pane fade" id="work__tab2" role="tabpanel" aria-labelledby="work__tab2-tab">
                                    {WorkSectionContentListTwo.map((val, i) => (
                                        <div className="work__item" key={i}>
                                            <div className="work__inner">
                                                <div className="work__thumb">
                                                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} style={{ height: '244px', width: 'auto', margin: '0 auto' }} />
                                                </div>
                                                <div className="work__content">
                                                    <h3>{val.title}</h3>
                                                    <p>{val.desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="tab-pane fade" id="work__tab3" role="tabpanel" aria-labelledby="work__tab3-tab">
                                    {WorkSectionContentListThree.map((val, i) => (
                                        <div className="work__item" key={i}>
                                            <div className="work__inner">
                                                <div className="work__thumb">
                                                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} style={{ height: '244px', width: 'auto', margin: '0 auto' }} />
                                                </div>
                                                <div className="work__content">
                                                    <h3>{val.title}</h3>
                                                    <p>{val.desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="tab-pane fade" id="work__tab4" role="tabpanel" aria-labelledby="work__tab4-tab">
                                    {WorkSectionContentListFour.map((val, i) => (
                                        <div className="work__item" key={i}>
                                            <div className="work__inner">
                                                <div className="work__thumb">
                                                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} style={{ height: '244px', width: 'auto', margin: '0 auto' }} />
                                                </div>
                                                <div className="work__content">
                                                    <h3>{val.title}</h3>
                                                    <p>{val.desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
}
 
export default WorkSection;