import {BrowserRouter, Routes, Route } from "react-router-dom";
import Swiper from 'swiper';
import 'swiper/css';
import ScrollToTop from './component/layout/scrolltop';
import ContactUs from "./pages/contact";
import ErrorPage from "./pages/errorpage";
import HomePage from "./pages/home";
import LogIn from "./pages/login";
import MemberDetails from "./pages/member-single";
import MembersPage from "./pages/members";
import MembershipPage from "./pages/membership";
import ShopCart from "./pages/shopcart";
import SignUp from "./pages/signup";
import Policy from "./pages/policy";
import UserRoleSelector from "./pages/custom/userRoleSelector";
import {createContext, useEffect, useState} from "react";
import CareGiverTypeSelector from "./pages/custom/careGiverTypeSelector";
import MenuPage from "./pages/custom/menuPage";
import RestrictedProfileForm from "./pages/custom/restrictedProfileForm";

export const UserTypeContext = createContext('company');

function App() {
	let [isOnTop, setIsOnTop] =useState(window.scrollY < 200);

	useEffect(() => {
		window.onscroll = () =>
			setIsOnTop(window.scrollY < 200);

		return () => (window.onscroll = null);
	}, []);

	const [isOpen, setIsOpen] = useState(false);
	const [userType, setUserType] = useState('company')
	const userTypes = ['company', 'giver', 'receiver'];

	return (
		<UserTypeContext.Provider value={userType}>
			<div className="App">
				<BrowserRouter>
					<ScrollToTop />
					<Routes>
						<Route path="/" element={<HomePage />} />
						<Route path="membership" element={<MembershipPage />} />
						<Route path="*" element={<ErrorPage />} />
						<Route path="members" element={<MembersPage />} />
						<Route path="shop-cart" element={<ShopCart />} />
						<Route path="contact" element={<ContactUs />} />
						<Route path="register" element={<SignUp />} />
						<Route path="login" element={<LogIn />} />
						<Route path="member-single" element={<MemberDetails />} />
						<Route path="policy" element={<Policy />} />
						<Route path="role-select" element={<UserRoleSelector />} />
						<Route path="caregiver-type-select" element={<CareGiverTypeSelector />} />
						<Route path="user-menu" element={<MenuPage />} />
            <Route path="create-profile" element={<RestrictedProfileForm />} />
					</Routes>
					<div>
						<a href="#" className="scrollToTop" style={{opacity: isOnTop ? 0 : 1, bottom: isOnTop ? '-30%' : '3%'}}><i
							className="fa-solid fa-angle-up"></i></a>
					</div>
				</BrowserRouter>
			</div>
			<div id="temp-user-selector">
				<button className="default-btn style-2" onClick={() => setIsOpen(!isOpen)}><span>/</span></button>
				{isOpen && <>
					<span className="desc">Select user type:</span>
					{userTypes.map(typeName => <button className={`default-btn ${typeName === userType ? 'active' : ''}`} onClick={() => setUserType(typeName)}><span>{typeName}</span></button>)}
				</>}
			</div>
		</UserTypeContext.Provider>
	);
}

export default App;
