import { Fragment } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/layout/footer";
import HeaderTwo from "../component/layout/headertwo";
import PageHeader from "../component/layout/pageheader";


let ShopCartList = [
    {
        imgUrl: 'assets/images/member/profile/profile.jpg',
        imgAlt: 'Profile picture',
        title: 'Contact information',
        title2: 'Emily Jenkins',
        price: 'CHF 5.0',
        totalPrice: 'CHF 5.0',
    }
]

function ShopCart() {
        return (
            <Fragment>
                <HeaderTwo isLoggedIn/>
                <PageHeader title={'Shop Cart Page'} curPage={'Shop Cart'} />
                <div className="shop-cart padding-top padding-bottom">
                    <div className="container">
                        <div className="section-wrapper">
                            <div className="cart-top">
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="cat-product">Product</th>
                                            <th className="cat-price">Price</th>
                                            <th className="cat-toprice">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {ShopCartList.map((val, i) => (
                                            <tr key={i}>
                                                <td className="product-item cat-product">
                                                    <div className="p-thumb">
                                                        <Link to="#"><img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} /></Link>
                                                    </div>
                                                    <div className="p-content">
                                                        {val.title}
                                                        <br/>
                                                        <span style={{ fontSize: '0.9rem', fontWeight: 'normal' }}>{val.title2}</span>
                                                    </div>
                                                </td>
                                                <td className="cat-price">{val.price}</td>
                                                <td className="cat-toprice">{val.totalPrice}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="cart-bottom">
                                <div className="cart-checkout-box">
                                    <form className="coupon" action="/">
                                        <input type="text" name="coupon" placeholder="Coupon Code..." className="cart-page-input-text" />
                                        <button type="submit" className="default-btn ms-2 px-4"><span>Apply</span></button>
                                    </form>
                                </div>
                                <div className="shiping-box">
                                    <div className="row">
                                        <div className="col-md-6 col-12">
                                            <div className="calculate-shiping">
                                                <h3>Payment method</h3>
                                                <div className="outline-select">
                                                    <select>
                                                        <option>Stripe</option>
                                                    </select>
                                                    <span className="select-icon"><i className="icofont-rounded-down"></i></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="cart-overview">
                                                <h3>Cart Totals</h3>
                                                <ul className="codex pb-2">
                                                    <li>
                                                        <span className="pull-left">Cart Subtotal</span>
                                                        <p className="pull-right">CHF 5.0</p>
                                                    </li>
                                                    <li>
                                                        <span className="pull-left">VAT 8.8%</span>
                                                        <p className="pull-right">CHF 0.44</p>
                                                    </li>
                                                    <li>
                                                        <span className="pull-left">Order Total</span>
                                                        <p className="pull-right">CHF 5.44</p>
                                                    </li>
                                                </ul>
                                                <form className="cart-checkout" action="/">
                                                    <button type="submit" className="default-btn style-2 d-block" style={{ marginLeft: 'auto'}}><span>Finalize order</span></button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        );
}
 
export default ShopCart;