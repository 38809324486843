import Profiles from "../../component/custom/profiles";
import React, {useContext, useState} from "react";
import Select from "react-select";
import {UserTypeContext} from "../../App";

const countryOptions = [
  { value: 'Albania', label: 'Albania' },
  { value: 'Andorra', label: 'Andorra' },
  { value: 'Austria', label: 'Austria' },
  { value: 'Belarus', label: 'Belarus' },
  { value: 'Belgium', label: 'Belgium' },
  { value: 'Bosnia and Herzegovina', label: 'Bosnia and Herzegovina' },
  { value: 'Bulgaria', label: 'Bulgaria' },
  { value: 'Croatia', label: 'Croatia' },
  { value: 'Cyprus', label: 'Cyprus' },
  { value: 'Czech Republic', label: 'Czech Republic' },
  { value: 'Denmark', label: 'Denmark' },
  { value: 'Estonia', label: 'Estonia' },
  { value: 'Finland', label: 'Finland' },
  { value: 'France', label: 'France' },
  { value: 'Germany', label: 'Germany' },
  { value: 'Greece', label: 'Greece' },
  { value: 'Hungary', label: 'Hungary' },
  { value: 'Iceland', label: 'Iceland' },
  { value: 'Ireland', label: 'Ireland' },
  { value: 'Italy', label: 'Italy' },
  { value: 'Kosovo', label: 'Kosovo' },
  { value: 'Latvia', label: 'Latvia' },
  { value: 'Liechtenstein', label: 'Liechtenstein' },
  { value: 'Lithuania', label: 'Lithuania' },
  { value: 'Luxembourg', label: 'Luxembourg' },
  { value: 'Malta', label: 'Malta' },
  { value: 'Moldova', label: 'Moldova' },
  { value: 'Monaco', label: 'Monaco' },
  { value: 'Montenegro', label: 'Montenegro' },
  { value: 'Netherlands', label: 'Netherlands' },
  { value: 'North Macedonia', label: 'North Macedonia' },
  { value: 'Norway', label: 'Norway' },
  { value: 'Poland', label: 'Poland' },
  { value: 'Portugal', label: 'Portugal' },
  { value: 'Romania', label: 'Romania' },
  { value: 'Russia', label: 'Russia' },
  { value: 'San Marino', label: 'San Marino' },
  { value: 'Serbia', label: 'Serbia' },
  { value: 'Slovakia', label: 'Slovakia' },
  { value: 'Slovenia', label: 'Slovenia' },
  { value: 'Spain', label: 'Spain' },
  { value: 'Sweden', label: 'Sweden' },
  { value: 'Switzerland', label: 'Switzerland' },
  { value: 'Ukraine', label: 'Ukraine' },
  { value: 'United Kingdom', label: 'United Kingdom' },
  { value: 'Vatican City', label: 'Vatican City' }
]

export default function Account() {
  const userType = useContext(UserTypeContext);

  const [company, setCompany] = useState({
    name: 'CareVista Services',
    address: '45 Rue de la République, Paris',
    country: { value: 'France', label: 'France' },
    website: 'www.carevistaservices.eu',
    contact: {
      name: 'Maria Schmidt',
      email: 'info@carevistaservices.eu',
      phone: '+33 1 23 45 67 89'
    }
  })

  const [user, setUser] = useState({
    firstname: 'Emily',
    lastname: 'Jenkins',
  })

  return <>
    <div className="mb-4">
      {userType !== 'company' && <h3>Account</h3>}
      {userType === 'company' && <h3>Company details</h3>}
    </div>
    <form className="form-basic" action="#">
      {userType === 'company' && <>
        <label>Company name</label>
        <input type="text" value={company.name} onChange={(e) => setCompany({...company, name: e.target.value})}/>
        <label>Company address</label>
        <input type="text" value={company.address} onChange={(e) => setCompany({...company, address: e.target.value})}/>
        <label>Country</label>
        <Select options={countryOptions} defaultValue={company.country} styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            padding: '10px',
            marginBottom: '20px',
            border: '1px solid #2133661a'
          }),
        }} name='country'/>
        <label>Company website</label>
        <input type="text" value={company.website} onChange={(e) => setCompany({...company, website: e.target.value})}/>
        <label>Company logo</label>
        <img className="mt-4 mb-4 w-25" src="assets/images/company/placeholder.jfif" alt="Company logo"/>
        <button type="button" className="default-btn d-block mb-4"><span>Change logo</span></button>
        <hr/>
        <div className="row">
          <div className="col-6">
            <label>Contact first name</label>
            <input type="text" value={user.firstname} onChange={(e) => setUser({...user, firstname: e.target.value})}/>
          </div>
          <div className="col-6">
            <label>Contact last name</label>
            <input type="text" value={user.lastname} onChange={(e) => setUser({...user, lastname: e.target.value})}/>
          </div>
        </div>
        <label>Contact email</label>
        <input type="email" value={company.contact.email} onChange={(e) => setCompany({...company, contact: {...company.contact, email: e.target.value}})}/>
        <label>Contact phone</label>
        <input type="text" value={company.contact.phone} onChange={(e) => setCompany({...company, contact: {...company.contact, phone: e.target.value}})}/>
      </>}
      {userType !== 'company' && <>
        <div className="row">
          <div className="col-6">
            <label>First name</label>
            <input type="text" value={user.firstname} onChange={(e) => setUser({...user, firstname: e.target.value})}/>
          </div>
          <div className="col-6">
            <label>Last name</label>
            <input type="text" value={user.lastname} onChange={(e) => setUser({...user, lastname: e.target.value})}/>
          </div>
        </div>
      </>}
      <div className="d-flex align-items-baseline gap-3">
        <button type="button" className="default-btn style-2 d-block"><span>Save</span></button>
        <button type="button" className="default-btn d-block mt-3 mb-4"><span>Change email address</span></button>
        <button type="button" className="default-btn d-block mt-3 mb-4"><span>Change password</span></button>
      </div>
    </form>
  </>
}