import {Link} from "react-router-dom";
import React from "react";
import Pagination from "../section/pagination";

let MemberContentList = [
  {
    imgUrl: 'assets/images/member/male/01.png',
    imgAlt: 'Profile',
    title: 'Rajib Raj',
    activity: 'CPR and First Aid certification',
    className: 'member__activity',
    status: 'Available',
  },
  {
    imgUrl: 'assets/images/member/female/01.png',
    imgAlt: 'Profile',
    title: 'Angel Mili',
    activity: 'CNA certification',
    className: 'member__activity member__activity--ofline',
    status: 'Temporary',
  },
  {
    imgUrl: 'assets/images/member/male/02.png',
    imgAlt: 'Profile',
    title: 'Kim Knudsen',
    activity: 'Caregiving experience',
    className: 'member__activity',
    status: 'Not available',
  },
  {
    imgUrl: 'assets/images/member/female/02.png',
    imgAlt: 'Profile',
    title: 'Umme Nishat',
    activity: 'Physiotherapist',
    className: 'member__activity',
    status: 'Available',
  },
  {
    imgUrl: 'assets/images/member/male/03.jpg',
    imgAlt: 'Profile',
    title: 'Sajahan Sagor',
    activity: 'Valid driver\'s license',
    className: 'member__activity member__activity--ofline',
    status: 'Not available',
  },
  {
    imgUrl: 'assets/images/member/female/03.png',
    imgAlt: 'Profile',
    title: 'Zinat Zaara',
    activity: 'CPR and First Aid certification',
    className: 'member__activity',
    status: 'Temporary',
  },
  {
    imgUrl: 'assets/images/member/male/04.jpg',
    imgAlt: 'Profile',
    title: 'Rajib Ahmed',
    activity: 'Caregiving experience',
    className: 'member__activity',
    status: 'Available',
  },
  {
    imgUrl: 'assets/images/member/female/04.png',
    imgAlt: 'Profile',
    title: 'Sabrina Kabir',
    activity: 'Valid driver\'s license',
    className: 'member__activity',
    status: 'Temporary',
  },
  {
    imgUrl: 'assets/images/member/male/05.jpg',
    imgAlt: 'Profile',
    title: 'Somrat Islam',
    activity: 'Physiotherapist',
    className: 'member__activity member__activity--ofline',
    status: 'Available',
  },
  {
    imgUrl: 'assets/images/member/female/05.png',
    imgAlt: 'Profile',
    title: 'Rasmi Rahani',
    activity: 'CNA certification',
    className: 'member__activity',
    status: 'Not available',
  },
  {
    imgUrl: 'assets/images/member/male/06.jpg',
    imgAlt: 'Profile',
    title: 'Benjamin Cooper',
    activity: 'Valid driver\'s license',
    className: 'member__activity',
    status: 'Temporary',
  },
  {
    imgUrl: 'assets/images/member/female/06.png',
    imgAlt: 'Profile',
    title: 'Sophia Anderson',
    activity: 'CPR and First Aid certification',
    className: 'member__activity member__activity--ofline',
    status: 'Not available',
  },
  {
    imgUrl: 'assets/images/member/male/07.jpg',
    imgAlt: 'Profile',
    title: 'Alexander Roberts',
    activity: 'Physiotherapist',
    className: 'member__activity',
    status: 'Available',
  },
  {
    imgUrl: 'assets/images/member/female/08.jpg',
    imgAlt: 'Profile',
    title: 'Emily Thompson',
    activity: 'Caregiving experience',
    className: 'member__activity',
    status: 'Temporary',
  },
  {
    imgUrl: 'assets/images/member/male/08.jpg',
    imgAlt: 'Profile',
    title: 'Ethan Johnson',
    activity: 'CNA certification',
    className: 'member__activity member__activity--ofline',
    status: 'Not available',
  },
]

export default function Profiles({canEdit = false}) {
  return <>
    <div className="row g-0 justify-content-center mx-12-none">
      {MemberContentList.map((val, i) => (
        <div className="member__item" key={i}>
          <div className="member__inner">
            <div className="member__thumb">
              <Link to="/member-single" className="w-100"><img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} /></Link>
              <span className={`member__activity member__activity--right ${val.status === 'Temporary' && 'yellow'} ${val.status === 'Not available' && 'red'}`}>{val.status}</span>
            </div>
            <div className={`member__content ${canEdit ? 'editable' : ''}`}>
              <Link to="/member-single"><h5>{val.title}</h5></Link>
              {!canEdit && <p>{val.activity}</p>}
              {canEdit && <div className="actions-wrap d-flex justify-content-between align-items-center">
                <button className="default-btn style-2" type="button"><span>Edit</span></button>
                <button className="default-btn style-2 delete" type="button"><span>Delete</span></button>
              </div>}
            </div>
          </div>
        </div>
      ))}
    </div>
  </>
}