import {Link} from "react-router-dom";

let ShopCartList = [
  {
    id: '1',
    date: '2024-02-23',
    product: 'Monthly subscription',
    price: 15,
    status: 0
  },
  {
    id: '2',
    date: '2024-01-23',
    product: 'Monthly subscription',
    price: 15,
    status: 1
  }
]

export default function Orders() {
  return <>
    <h3 className="mb-4">Orders</h3>
    <div className="ordersTable">
      <table>
        <thead>
        <tr>
          <th>ID</th>
          <th>Date</th>
          <th>Product</th>
          <th className="text-center">Status</th>
          <th className="d-none d-lg-table-cell">Net Price</th>
          <th className="d-none d-lg-table-cell">VAT 8.1%</th>
          <th>Total Price</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        {ShopCartList.map((val, i) => (
          <tr key={val.id}>
            <td>{val.id}</td>
            <td>{val.date}</td>
            <td>{val.product}</td>
            <td><span className={`custom-pill d-block w-100 text-center ${val.status === 0 ? 'orange' : 'green'}`}>{val.status === 0 ? 'Pending' : 'Paid'}</span></td>
            <td className="d-none d-lg-table-cell">CHF {val.price}</td>
            <td className="d-none d-lg-table-cell">CHF {val.price * 8.1 / 100}</td>
            <td>CHF {val.price + val.price * 8.1 / 100}</td>
            <td><button type="button" className="default-btn style-2" style={{ padding: '10px' }}><span>Invoice</span></button></td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  </>
}