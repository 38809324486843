import React from "react";
import Select from "react-select";
import {Link} from "react-router-dom";

const title = "Find a caregiver";
const desc = "The perfect healthcare worker is just a few clicks away.";

const labelchangetwo = "Gender";
const labelchangethree = "Allocation";
const labelchangefour = "Region";
const labelchangefive = "Language";
const btnText = "Search";

const genderOptions = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
    { value: 'All', label: 'All' },
]

const regionOptions = [
    { value: 'Aargau', label: 'Aargau' },
    { value: 'Appenzell Ausserrhoden', label: 'Appenzell Ausserrhoden' },
    { value: 'Appenzell Innerrhoden', label: 'Appenzell Innerrhoden' },
    { value: 'Basel-Landschaft', label: 'Basel-Landschaft' },
    { value: 'Basel-Stadt', label: 'Basel-Stadt' },
    { value: 'Bern', label: 'Bern' },
    { value: 'Fribourg', label: 'Fribourg' },
    { value: 'Geneva', label: 'Geneva' },
    { value: 'Glarus', label: 'Glarus' },
    { value: 'Graubünden', label: 'Graubünden' },
    { value: 'Jura', label: 'Jura' },
    { value: 'Lucerne', label: 'Lucerne' },
    { value: 'Neuchâtel', label: 'Neuchâtel' },
    { value: 'Nidwalden', label: 'Nidwalden' },
    { value: 'Obwalden', label: 'Obwalden' },
    { value: 'Schaffhausen', label: 'Schaffhausen' },
    { value: 'Schwyz', label: 'Schwyz' },
    { value: 'Solothurn', label: 'Solothurn' },
    { value: 'St. Gallen', label: 'St. Gallen' },
    { value: 'Thurgau', label: 'Thurgau' },
    { value: 'Ticino', label: 'Ticino' },
    { value: 'Uri', label: 'Uri' },
    { value: 'Valais', label: 'Valais' },
    { value: 'Vaud', label: 'Vaud' },
    { value: 'Zug', label: 'Zug' },
    { value: 'Zurich', label: 'Zurich' }
]

const languageOptions = [
    { value: 'German', label: 'German'},
    { value: 'English', label: 'English'},
    { value: 'French', label: 'French'},
    { value: 'Italian', label: 'Italian'}
]

const allocationOptions = [
    { value: 'stay-in', label: 'Stay in (Permanent)' },
    { value: 'casual', label: 'Casual' },
]

const imgLink = "assets/images/banner/01.svg";
const imgAlt = "Banner image";

function BannerOne() {
        return (
            <div className="banner padding-top padding-bottom bg_img" style={{backgroundImage: "url(/assets/images/banner/bg.jpg)"}}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-12">
                            <div className="banner__content wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".5s">
                                <div className="banner__title">
                                    <h2>{title}</h2>
                                    <p>{desc}</p>
                                </div>
                                <div className="banner__form">
                                    <form action="#">
                                        <div className="row">
                                            <div className="col-6"><div className="banner__list">
                                                <label>{labelchangethree}</label>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <Select options={allocationOptions} styles={{
                                                            control: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                padding: '10px',
                                                                marginBottom: '20px',
                                                                border: '1px solid #2133661a'
                                                            }),
                                                        }} name='allocation'/>
                                                    </div>
                                                </div>
                                            </div></div>
                                            <div className="col-6"><div className="banner__list">
                                                <label>{labelchangetwo}</label>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <Select options={genderOptions} styles={{
                                                            control: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                padding: '10px',
                                                                marginBottom: '20px',
                                                                border: '1px solid #2133661a'
                                                            }),
                                                        }} name='gender'/>
                                                    </div>
                                                </div>
                                            </div></div>
                                        </div>
                                        <div className="banner__list">
                                            <label>{labelchangefour}</label>
                                            <div className="row">
                                                <div className="col-12">
                                                        <Select options={regionOptions} isMulti classNamePrefix="react-select" styles={{
                                                            control: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                padding: '10px',
                                                                marginBottom: '20px',
                                                                border: '1px solid #2133661a'
                                                            }),
                                                        }} name='region'/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="banner__list">
                                            <label>{labelchangefive}</label>
                                            <div className="row">
                                                <div className="col-12">
                                                    <Select options={languageOptions} isMulti classNamePrefix="react-select" styles={{
                                                        control: (baseStyles, state) => ({
                                                            ...baseStyles,
                                                            padding: '10px',
                                                            marginBottom: '20px',
                                                            border: '1px solid #2133661a'
                                                        }),
                                                    }} name='language'/>
                                                </div>
                                            </div>
                                        </div>
                                        <Link to="/members" className="default-btn style-2 d-block"><span className="d-block text-center">{btnText}</span></Link>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12" style={{ display: 'flex', alignItems: 'center' }}>
                            <div className="banner__thumb banner__thumb--thumb1 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="1s">
                                <img src={imgLink} alt={imgAlt} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
}

export default BannerOne;