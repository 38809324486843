import { Component, Fragment } from "react";
import HeaderTwo from "../component/layout/headertwo";
import PageHeader from "../component/layout/pageheader";
import Footer from "../component/layout/footer";

class Policy extends Component {
    render() { 
        return (
            <Fragment>
                <HeaderTwo />
                <PageHeader title={'Privacy & Policy'} curPage={'Policy'} />
                <section className="terms-section padding-bottom padding-top">
                    <div className="container">
                        <div className="terms-content">
                            <div className="terms-header">
                                <h4>Privacy &amp; Policy</h4>
                                <p><span className="theme-color fw-bold">Last Updated:</span> June 1, 2022</p>
                            </div>
                            <div className="terms-text">
                                <p className="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit doloribus iusto debitis similique voluptates nihil dicta praesentium atque, dolorum perspiciatis numquam possimus at cumque voluptatum, saepe aliquid ducimus ex aliquam fugiat nam velit. Possimus recusandae ut quod fuga, accusantium et quas corporis. Amet aliquid ex repellat doloribus alias! Dolores, ipsum.</p>
                                <ol>
                                    <li>
                                        <h5>Introduction</h5>
                                        <p>Terms of Service (“Terms”) govern your access to and use of the Anftiz website(s), our
                                            APIs,
                                            mobile app (the “App”),
                                            and any other software, Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                            Corporis,
                                            commodi?
                                            your own discretion, to
                                            connect directly with others to purchase, sell, or transfer NFTs on public blockchains
                                            (collectively, the “Service”).
                                            “NFT” in these Terms means a non-fungible token or similar digital item implemented on a
                                            blockchain (such as the
                                            Ethereum blockchain), which uses smart contracts to link to or otherwise be associated
                                            with
                                            certain content or data.</p>
                                    </li>
                                    <li>
                                        <h5>Accessing the Service</h5>
                                        <p>Terms of Service (“Terms”) govern your access to and use of the Anftiz website(s), our
                                            APIs,
                                            mobile app (the “App”),
                                            and any other software, Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                            Corporis,
                                            commodi?
                                            your own discretion, to
                                            connect directly with others to purchase, sell, or transfer NFTs on public blockchains
                                            (collectively, the “Service”).
                                            “NFT” in these Terms means a non-fungible token or similar digital item implemented on a
                                            blockchain (such as the
                                            Ethereum blockchain), which uses smart contracts to link to or otherwise be associated
                                            with
                                            certain content or data.</p>
                                        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Et labore officiis natus?
                                            Assumenda perferendis, sint nisi, a numquam ut rerum quod voluptas nam dignissimos nemo
                                            neque quisquam ullam. Sed soluta repellendus hic delectus neque atque eos possimus velit
                                            pariatur vero amet, blanditiis, sint incidunt. Porro voluptatum cumque, vel qui,
                                            corrupti fuga dolor quod, quaerat optio cum nam? Quaerat odit vitae beatae tenetur
                                            necessitatibus neque voluptate, ipsa nesciunt mollitia aspernatur quas autem quia optio
                                            temporibus! Sequi dolor harum distinctio natus nobis doloribus quas non tempora modi
                                            enim incidunt commodi placeat quae molestiae ullam, explicabo repudiandae dolores
                                            veritatis aut perspiciatis odit. Aspernatur?</p>
                                    </li>
                                    <li>
                                        <h5>Ownership</h5>
                                        <p>Terms of Service (“Terms”) govern your access to and use of the Anftiz website(s), our
                                            APIs,
                                            mobile app (the “App”),
                                            and any other software, Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                            Corporis,
                                            commodi?
                                            your own discretion, to
                                            connect directly with others to purchase, sell, or transfer NFTs on public blockchains
                                            (collectively, the “Service”).
                                            “NFT” in these Terms means a non-fungible token or similar digital item implemented on a
                                            blockchain (such as the
                                            Ethereum blockchain), which uses smart contracts to link to or otherwise be associated
                                            with
                                            certain content or data.</p>
                                        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Et labore officiis natus?
                                            Assumenda perferendis, sint nisi, a numquam ut rerum quod voluptas nam dignissimos nemo
                                            neque quisquam ullam. Sed soluta repellendus hic delectus neque atque eos possimus velit
                                            pariatur vero amet, blanditiis, sint incidunt. Porro voluptatum cumque, vel qui,
                                            corrupti fuga dolor quod, quaerat optio cum nam? Quaerat odit vitae beatae tenetur
                                            necessitatibus neque voluptate, ipsa nesciunt mollitia aspernatur quas autem quia optio
                                            temporibus! Sequi dolor harum distinctio natus nobis doloribus quas non tempora modi
                                            enim incidunt commodi placeat quae molestiae ullam, explicabo repudiandae dolores
                                            veritatis aut perspiciatis odit. Aspernatur?</p>
                                    </li>
                                    <li>
                                        <h5>Third-Party Content and Services</h5>
                                        <p>Terms of Service (“Terms”) govern your access to and use of the Anftiz website(s), our
                                            APIs,
                                            mobile app (the “App”),
                                            and any other software, Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                            Corporis,
                                            commodi?
                                            your own discretion, to
                                            connect directly with others to purchase, sell, or transfer NFTs on public blockchains
                                            (collectively, the “Service”).
                                            “NFT” in these Terms means a non-fungible token or similar digital item implemented on a
                                            blockchain (such as the
                                            Ethereum blockchain), which uses smart contracts to link to or otherwise be associated
                                            with
                                            certain content or data.</p>
                                        <ol>
                                            <li>Use or attempt to use another user’s Account without authorization from such user;
                                            </li>
                                            <li>Pose as another person or entity;</li>
                                            <li>Distribute spam, including through sending unwanted NFTs to other users;</li>
                                            <li>
                                                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ipsum iste laborum
                                                possimus nostrum harum impedit rerum porro quae dignissimos aut.
                                                <ul>
                                                    <li>Lorem ipsum dolor sit amet consectetur</li>
                                                    <li>Lorem ipsum dolor sit amet consectetur</li>
                                                    <li>Lorem ipsum dolor sit amet consectetur</li>
                                                    <li>Lorem ipsum dolor sit amet consectetur</li>
                                                </ul>
                                            </li>
                                            <li>Use the Service or data collected from our Service for any advertising or direct
                                                marketing activity (including without
                                                limitation, email marketing, SMS marketing, and telemarketing);</li>
                                            <li>Bypass or ignore instructions that control access to the Service, including
                                                attempting to circumvent any</li>
                                        </ol>
                                        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Et labore officiis natus?
                                            Assumenda perferendis, sint nisi, a numquam ut rerum quod voluptas nam dignissimos nemo
                                            neque quisquam ullam. Sed soluta repellendus hic delectus neque atque eos possimus velit
                                            pariatur vero amet, blanditiis, sint incidunt. Porro voluptatum cumque, Aspernatur?</p>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </Fragment>
        );
    }
}
 
export default Policy;