import { Component, useState } from "react";
import { Link } from "react-router-dom";

const title = "Most Popular Members";
const desc = "Explore our top-rated workers, renowned for their expertise and exceptional care. Discover the best in healthcare assistance tailored to your needs.";
const btnText = "See More Popular";

let MemberDataList = [
    {
        id: 1,
        imgUrl: 'assets/images/member/female/03.png',
        imgAlt: 'Healthcare Worker',
        activity: 'Basel',
        name: 'Zinat Zaara',
        qualification: 'CPR and First Aid certification'
    },
    {
        id: 2,
        imgUrl: 'assets/images/member/female/01.png',
        imgAlt: 'Healthcare Worker',
        activity: 'Bern',
        name: 'Arika Q Smith',
        qualification: 'CNA certification'
    },
    {
        id: 3,
        imgUrl: 'assets/images/member/female/05.png',
        imgAlt: 'Healthcare Worker',
        activity: 'Geneva',
        name: 'Rasmi Rahani',
        qualification: 'CNA certification'
    },
    {
        id: 4,
        imgUrl: 'assets/images/member/female/02.png',
        imgAlt: 'Healthcare Worker',
        activity: 'Zurich',
        name: 'Karolin Kuhn',
        qualification: 'Physiotherapist'
    },
    {
        id: 5,
        imgUrl: 'assets/images/member/male/03.jpg',
        imgAlt: 'Healthcare Worker',
        activity: 'Lausanne',
        name: 'Dieter Hoover',
        qualification: 'Valid driver\'s license'
    },
]

function MemberSection() {

    const [items, setItems] = useState(MemberDataList);
    const filterItem = (categItem) => {
        const updateItems = MemberDataList.filter((curElem) => {
            return curElem.catagory === categItem;
        });
        setItems(updateItems);
    }
    return (
        <div className="member padding-top padding-bottom overflow-hidden">
            <div className="container">
                <div className="section__header style-2 text-center wow fadeInUp" data-wow-duration="1.5s">
                    <h2>{title}</h2>
                    <p>{desc}</p>
                </div>
                <div className="section__wrapper wow fadeInUp" data-wow-duration="1.5s">

                    <div className="member__grid d-flex flex-wrap justify-content-center mx-12-none">
                        {
                            items.map((elem) => {
                            const { id, imgUrl, imgAlt, activity, name, qualification } = elem;
                                return (
                                    <div className="member__item male" key={id}>
                                        <div className="member__inner">
                                            <div className="member__thumb">
                                                <Link to="/member-single" className="w-100">
                                                    <img src={`${imgUrl}`} alt={`${imgAlt}`} />
                                                    <span className="member__activity member__activity--blackout">{activity}</span>
                                                </Link>
                                            </div>
                                            <div className="member__content">
                                                <Link to="/member-single"><h5>{name}</h5></Link>
                                                {qualification && <p>{qualification}</p>}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="text-center mt-4">
                        <Link to="/members" className="default-btn"><span>{btnText}</span></Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default MemberSection;