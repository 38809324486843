import React, { Component } from "react";
import Select from "react-select";

const labelchangetwo = "Gender";
const labelchangethree = "Allocation";
const labelchangefour = "Region";
const labelchangefive = "Language";
const labelchangesix = "Skills";
const labelchangeseven = "Qualifications";
const btnText = "Search";

const genderOptions = [
    { value: 'all', label: 'All genders' },
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' },
]

const regionOptions = [
    { value: 'Aargau', label: 'Aargau' },
    { value: 'Appenzell Ausserrhoden', label: 'Appenzell Ausserrhoden' },
    { value: 'Appenzell Innerrhoden', label: 'Appenzell Innerrhoden' },
    { value: 'Basel-Landschaft', label: 'Basel-Landschaft' },
    { value: 'Basel-Stadt', label: 'Basel-Stadt' },
    { value: 'Bern', label: 'Bern' },
    { value: 'Fribourg', label: 'Fribourg' },
    { value: 'Geneva', label: 'Geneva' },
    { value: 'Glarus', label: 'Glarus' },
    { value: 'Graubünden', label: 'Graubünden' },
    { value: 'Jura', label: 'Jura' },
    { value: 'Lucerne', label: 'Lucerne' },
    { value: 'Neuchâtel', label: 'Neuchâtel' },
    { value: 'Nidwalden', label: 'Nidwalden' },
    { value: 'Obwalden', label: 'Obwalden' },
    { value: 'Schaffhausen', label: 'Schaffhausen' },
    { value: 'Schwyz', label: 'Schwyz' },
    { value: 'Solothurn', label: 'Solothurn' },
    { value: 'St. Gallen', label: 'St. Gallen' },
    { value: 'Thurgau', label: 'Thurgau' },
    { value: 'Ticino', label: 'Ticino' },
    { value: 'Uri', label: 'Uri' },
    { value: 'Valais', label: 'Valais' },
    { value: 'Vaud', label: 'Vaud' },
    { value: 'Zug', label: 'Zug' },
    { value: 'Zurich', label: 'Zurich' }
]

const languageOptions = [
    { value: 'German', label: 'German' },
    { value: 'English', label: 'English' },
    { value: 'French', label: 'French' },
    { value: 'Italian', label: 'Italian' }
]

const allocationOptions = [
    { value: 'stay-in', label: 'Stay in (Permanent)' },
    { value: 'casual', label: 'Casual' },
]

const skillOptions = [
    { value: 'Compassion and empathy', label: 'Compassion and empathy' },
    { value: 'Communication', label: 'Communication' },
    { value: 'Patience', label: 'Patience' },
    { value: 'Adaptability', label: 'Adaptability' },
    { value: 'Dependability and reliability', label: 'Dependability and reliability' },
    { value: 'Attention to detail', label: 'Attention to detail' },
    { value: 'Time management', label: 'Time management' },
    { value: 'Problem-solving', label: 'Problem-solving' },
    { value: 'Physical stamina', label: 'Physical stamina' },
    { value: 'Self-care', label: 'Self-care' }
]

const qualOptions = [
    { value: 'CPR and First Aid certification', label: 'CPR and First Aid certification' },
    { value: 'CNA certification', label: 'CNA certification' },
    { value: 'Caregiving experience', label: 'Caregiving experience' },
    { value: 'Physiotherapist', label: 'Physiotherapist' },
    { value: 'Valid driver\'s license', label: 'Valid driver\'s license' },
]

class AboutSectionFour extends Component {
    render() { 
        return (
            <div className="about about--style3 padding-top pt-xl-0">
                <div className="container">
                    <div className="section__wrapper wow fadeInUp" data-wow-duration="1.5s">
                        <form action="#">
                            <div className="banner__list">
                                <div className="row align-items-center row-cols-xl-3 row-cols-lg-3 row-cols-sm-2 row-cols-1">
                                    <div className="col mb-3">
                                        <label>{labelchangethree}</label>
                                        <Select options={allocationOptions} styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                padding: '7px',
                                                border: '1px solid #2133661a'
                                            }),
                                        }} name='allocation'/>
                                    </div>
                                    <div className="col mb-3">
                                        <label>{labelchangetwo}</label>
                                        <Select options={genderOptions} styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                padding: '7px',
                                                border: '1px solid #2133661a'
                                            }),
                                        }} name='gender'/>
                                    </div>
                                    <div className="col mb-3">
                                        <label>{labelchangefour}</label>
                                        <Select options={regionOptions} isMulti classNamePrefix="react-select" styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                padding: '7px',
                                                border: '1px solid #2133661a'
                                            }),
                                        }} name='region'/>
                                    </div>
                                    <div className="col mb-3">
                                        <label>{labelchangefive}</label>
                                        <Select options={languageOptions} isMulti classNamePrefix="react-select" styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                padding: '7px',
                                                border: '1px solid #2133661a'
                                            }),
                                        }} name='language'/>
                                    </div>
                                    <div className="col mb-3">
                                        <label>{labelchangesix}</label>
                                        <Select options={skillOptions} isMulti classNamePrefix="react-select" styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                padding: '7px',
                                                border: '1px solid #2133661a'
                                            }),
                                        }} name='skills'/>
                                    </div>
                                    <div className="col mb-3">
                                        <label>{labelchangeseven}</label>
                                        <Select options={qualOptions} isMulti classNamePrefix="react-select" styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                padding: '7px',
                                                border: '1px solid #2133661a'
                                            }),
                                        }} name='quals'/>
                                    </div>
                                    <div className="col-12 col-sm-12 col-lg-12 col-xl-12 mb-3">
                                        <button type="submit" className="default-btn reverse d-block" style={{ width: '32%', marginLeft: 'auto', marginRight: 'auto'}}><span>{btnText}</span></button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default AboutSectionFour;