import {Component} from "react";
import {Link, NavLink} from "react-router-dom";

let HeaderInfoList = [
  {
    iconName: 'fa-solid fa-phone',
    text: '+41 43 205 22 90',
  },
  {
    iconName: 'fa-solid fa-location-dot',
    text: 'Kreuzstrasse 24, 8008 Zürich, Schweiz',
  },
]

let HeaderSocialList = [
  {
    iconName: 'fa-brands fa-facebook-f',
    text: 'Facebook',
    link: '#'
  },
  {
    iconName: 'fa-brands fa-instagram',
    text: 'Instagram',
    link: '#'
  },
  {
    iconName: 'fa-brands fa-youtube',
    text: 'Youtube',
    link: '#'
  },
]

function HeaderTwo({ isLoggedIn = false }) {
  window.addEventListener('scroll', function () {
    var value = window.scrollY;
    if (value > 200) {
      document.querySelector('.header').classList.add(['header-fixed'], ['animated'], ['fadeInDown'])
    } else {
      document.querySelector('.header').classList.remove(['header-fixed'], ['animated'], ['fadeInDown'])
    }
  });

  return (
    <header className="header" id="navbar">
      <div className="header__top d-none d-lg-block">
        <div className="container">
          <div className="header__top--area">
            <div className="header__top--left">
              <ul>
                {HeaderInfoList.map((val, i) => (
                  <li key={i}>
                    <i className={val.iconName}></i> <span>{val.text}</span>
                  </li>
                ))}
              </ul>
            </div>
            <div className="header__top--right">
              <ul>
                {HeaderSocialList.map((val, i) => (
                  <li key={i}><a href={val.link}><i className={val.iconName}></i> {val.text}</a></li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="header__bottom">
        <div className="container">
          <nav className="navbar navbar-expand-lg">
            <Link className="navbar-brand" to="/" style={{paddingTop: '0', paddingBottom: '0'}}><img
              src="assets/images/logo/logo.png" alt="logo" style={{maxHeight: '50px'}}/></Link>
            <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false"
                    aria-label="Toggle navigation">
              <span className="navbar-toggler--icon"></span>
            </button>
            <div className="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
              <div className="navbar-nav mainmenu">
                <ul>
                  <li><NavLink to="/">Home</NavLink></li>
                  <li><NavLink to="/membership">Packages</NavLink></li>
                  <li><NavLink to="/contact">Contact</NavLink></li>
                  {!isLoggedIn && <li><NavLink to="/register">Register</NavLink></li>}
                </ul>
              </div>
              <div className="header__more">
                {!isLoggedIn && <Link to="/login" className="default-btn style-2"><span>Sign in</span></Link>}
                {isLoggedIn && <Link to="/user-menu" className="default-btn style-2"><span>Dashboard</span></Link>}
              </div>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default HeaderTwo;