import {Link} from "react-router-dom";

export default function SelectionBox({imgUrl, imgAlt, title, desc, btnText, btnLink}) {
  return (
    <div className="col-xl-6 col-lg-8 col-12 wow fadeInLeft" data-wow-duration="1.5s">
      <div className="work__item h-100">
        <div className="work__inner h-100">
          <div className="work__thumb">
            <img src={`${imgUrl}`} alt={`${imgAlt}`}/>
          </div>
          <div className="work__content">
            <h3>{title}</h3>
            <p>{desc} </p>
            <Link to={btnLink} className="default-btn style-2"><span>{btnText}</span></Link>
          </div>
        </div>
      </div>
    </div>
  );
}