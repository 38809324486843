import { useState } from "react";
import { Link } from "react-router-dom";

const title = "Welcome to Goldstück";
const desc = "Let's create your profile! Just fill in the fields below, and we’ll set up a new account.";
const accTitle = "Acount Details";

function SignUp() {
    const [regFirstname, setRegFirstname] = useState('');
    const [regLastname, setRegLastname] = useState('');
    const [regEmail, setRegEmail] = useState('');
    const [regPassword, setRegPassword] = useState('');
    const [regConPassword, setRegConPassword] = useState('');

        return (
            <section className="log-reg">
                <div className="top-menu-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-7">
                                <div className="logo">
                                    <Link to="/"><img src="assets/images/logo/logo.png" alt="logo" style={{ maxHeight: '50px' }} /></Link>
                                </div>
                            </div>
                            <div className="col-lg-4 col-5">
                                <Link to="/" className="backto-home"><i className="fas fa-chevron-left"></i> Back to Home</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="image"></div>
                        <div className="col-lg-7">
                            <div className="log-reg-inner">
                                <div className="section-header">
                                    <h2 className="title">{title} </h2>
                                    <p>{desc} </p>
                                </div>
                                <div className="main-content">
                                    <form action="#">
                                        <h4 className="content-title">{accTitle}</h4>
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-12 col-sm-6 mb-3 mb-sm-0">
                                                    <label>First name*</label>
                                                    <input
                                                      type="text"
                                                      name="name"
                                                      id="item01"
                                                      value={regFirstname}
                                                      onChange={(e)=>{setRegFirstname(e.target.value)}}
                                                      placeholder="Enter Your First Name *"
                                                      className="my-form-control"
                                                    />
                                                </div>
                                                <div className="col-12 col-sm-6">
                                                    <label>Last name*</label>
                                                    <input
                                                      type="text"
                                                      name="name"
                                                      id="item01"
                                                      value={regLastname}
                                                      onChange={(e)=>{setRegLastname(e.target.value)}}
                                                      placeholder="Enter Your Last Name *"
                                                      className="my-form-control"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Email Address*</label>
                                            <input
                                                type="email"
                                                name="email"
                                                id="item02"
                                                value={regEmail}
                                                onChange={(e)=>{setRegEmail(e.target.value)}}
                                                placeholder="Enter Your Email *"
                                                className="my-form-control"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Password*</label>
                                            <input
                                                type="password"
                                                name="password"
                                                id="item03"
                                                value={regPassword}
                                                onChange={(e)=>{setRegPassword(e.target.value)}}
                                                placeholder="Enter Your Password *"
                                                className="my-form-control"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Confirm Password*</label>
                                            <input
                                                type="password"
                                                name="password"
                                                id="item04"
                                                value={regConPassword}
                                                onChange={(e)=>{setRegConPassword(e.target.value)}}
                                                placeholder="Enter Your Password *"
                                                className="my-form-control"
                                            />
                                        </div>
                                        <div className="text-center">
                                            <Link to="/login" className="default-btn style-2"><span>Create Your Account</span></Link>
                                            {/*<button className="default-btn style-2"><span>Create Your Account</span></button>*/}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
}
 
export default SignUp;