import React from "react";
import Profiles from "../../component/custom/profiles";
import {Link} from "react-router-dom";

export default function CompanyProfiles() {
  return <>
    <div className="d-flex align-items-center">
      <h3>Profiles</h3>
      <Link to="/create-profile" className="default-btn style-2 d-block" style={{ marginLeft: 'auto', marginBottom: '10px' }}><span>Add new profile</span></Link>
    </div>
    <Profiles canEdit/>
  </>
}