import Footer from "../../component/layout/footer";
import HeaderOne from "../../component/layout/header";
import SelectionBox from "../../component/custom/selectionBox";

const title = "Caregiver type selection";
const desc = "Are you representing a caregiving company or are you an independent caregiver?";

let selectionData = [
  {
    imgUrl: 'assets/images/work/11.png',
    imgAlt: 'Care giver',
    title: 'Caregiver',
    desc: 'Ready to lend a helping hand? Join us as a caregiver. Provide essential support and companionship to those seeking assistance.',
    btnText: 'I am a caregiver',
    btnLink: '/user-menu',
  },
  {
    imgUrl: 'assets/images/work/12.png',
    imgAlt: 'Care giver company',
    title: 'Caregiver company',
    desc: 'Expand your caregiver network! Join us to offer services in daily tasks, personal care, and companionship to those in need.',
    btnText: 'I have a caregiver company',
    btnLink: '/user-menu',
  },
]

export default function CareGiverTypeSelector() {

  return (
    <>
      <HeaderOne/>
      <div className="work work--style2 padding-top padding-bottom bg_img" style={{backgroundImage: "url(/assets/images/bg-img/01.jpg)"}}>
        <div className="container">
          <div className="section__header style-2 text-center wow fadeInUp" data-wow-duration="1.5s">
            <h2>{title}</h2>
            <p>{desc}</p>
          </div>
          <div className="section__wrapper">
            <div className="row g-4 justify-content-center align-items-stretch">
              {selectionData.map(({imgUrl, imgAlt, title, desc, btnText, btnLink}, i) => (
                <SelectionBox key={i} imgUrl={imgUrl} imgAlt={imgAlt} title={title} desc={desc} btnText={btnText} btnLink={btnLink} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer white noNews/>
    </>
  )
}