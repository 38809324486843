import { Link, NavLink } from "react-router-dom";

function HeaderOne({noAuth = false}) {
    window.addEventListener('scroll', function () {
        var value = window.scrollY;
        if (value > 200) {
            document.querySelector('.header').classList.add(['header-fixed'], ['animated'], ['fadeInDown'])
        } else {
            document.querySelector('.header').classList.remove(['header-fixed'], ['animated'], ['fadeInDown'])
        }
    });

    return (
      <header className="header" id="navbar">
          <div className="header__bottom">
              <div className="container">
                  <nav className="navbar navbar-expand-lg">
                      <Link className="navbar-brand" to="/" style={{paddingTop: '0', paddingBottom: '0'}}><img
                        src="assets/images/logo/logo.png" alt="logo" style={{maxHeight: '50px'}}/></Link>
                      <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
                              data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup"
                              aria-expanded="false"
                              aria-label="Toggle navigation">
                          <span className="navbar-toggler--icon"></span>
                      </button>
                      <div className="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
                          <div className="navbar-nav mainmenu">
                              <ul>
                                  <li><NavLink to="/">Home</NavLink></li>
                                  <li><NavLink to="/membership">Packages</NavLink></li>
                                  <li><NavLink to="/contact">Contact</NavLink></li>
                                  {!noAuth && <li><NavLink to="/register">Register</NavLink></li>}
                              </ul>
                          </div>
                          {!noAuth && <div className="header__more">
                              <Link to="/login" className="default-btn style-2"><span>Sign in</span></Link>
                          </div>}
                      </div>
                  </nav>
              </div>
          </div>
      </header>
    );
}

export default HeaderOne;