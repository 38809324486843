import Footer from "../../component/layout/footer";
import HeaderOne from "../../component/layout/header";
import SelectionBox from "../../component/custom/selectionBox";

const title = "Role selection";
const desc = "Choose to offer care or seek support, guiding you to personalized assistance.";

let selectionData = [
  {
    imgUrl: 'assets/images/work/05.png',
    imgAlt: 'Care receiver',
    title: 'Looking for help?',
    desc: 'Need compassionate caregiving? Our team helps with daily tasks, personal care, and companionship, tailored to your needs.',
    btnText: 'I need help',
    btnLink: '/user-menu',
  },
  {
    imgUrl: 'assets/images/work/08.png',
    imgAlt: 'Care giver',
    title: 'Providing help',
    desc: 'Compassionate caregiver? Join us to support those in need with daily tasks, personal care, and companionship.',
    btnText: 'I offer help',
    btnLink: '/caregiver-type-select',
  },
]

export default function UserRoleSelector() {

  return (
    <>
      <HeaderOne/>
      <div className="work work--style2 padding-top padding-bottom bg_img" style={{backgroundImage: "url(/assets/images/bg-img/01.jpg)"}}>
        <div className="container">
          <div className="section__header style-2 text-center wow fadeInUp" data-wow-duration="1.5s">
            <h2>{title}</h2>
            <p>{desc}</p>
          </div>
          <div className="section__wrapper">
            <div className="row g-4 justify-content-center">
              {selectionData.map(({imgUrl, imgAlt, title, desc, btnText, btnLink}, i) => (
                <SelectionBox key={i} imgUrl={imgUrl} imgAlt={imgAlt} title={title} desc={desc} btnText={btnText} btnLink={btnLink} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer white noNews/>
    </>
  )
}