import { Fragment } from "react";
import Footer from "../component/layout/footer";
import HeaderOne from "../component/layout/header";
import AboutSection from "../component/section/about";
import BannerOne from "../component/section/banner";
import MeetSection from "../component/section/meet";
import MemberSection from "../component/section/member";
import WorkSection from "../component/section/work";
import WorkSectionTwo from "../component/section/worktwo";

function HomePage() {
  return (
    <Fragment>
      <HeaderOne/>
      <BannerOne/>
      <AboutSection/>
      <WorkSection/>
      <MeetSection/>
      <WorkSectionTwo/>
      <MemberSection/>
      <Footer/>
    </Fragment>
  );
}

export default HomePage;