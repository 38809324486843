import React, {useState} from "react";
import DatePicker from 'react-multi-date-picker';
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import Dropzone, {useDropzone} from 'react-dropzone'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const locationOptions = [
  { value: 'Aargau', label: 'Aargau' },
  { value: 'Appenzell Ausserrhoden', label: 'Appenzell Ausserrhoden' },
  { value: 'Appenzell Innerrhoden', label: 'Appenzell Innerrhoden' },
  { value: 'Basel-Landschaft', label: 'Basel-Landschaft' },
  { value: 'Basel-Stadt', label: 'Basel-Stadt' },
  { value: 'Bern', label: 'Bern' },
  { value: 'Fribourg', label: 'Fribourg' },
  { value: 'Geneva', label: 'Geneva' },
  { value: 'Glarus', label: 'Glarus' },
  { value: 'Graubünden', label: 'Graubünden' },
  { value: 'Jura', label: 'Jura' },
  { value: 'Lucerne', label: 'Lucerne' },
  { value: 'Neuchâtel', label: 'Neuchâtel' },
  { value: 'Nidwalden', label: 'Nidwalden' },
  { value: 'Obwalden', label: 'Obwalden' },
  { value: 'Schaffhausen', label: 'Schaffhausen' },
  { value: 'Schwyz', label: 'Schwyz' },
  { value: 'Solothurn', label: 'Solothurn' },
  { value: 'St. Gallen', label: 'St. Gallen' },
  { value: 'Thurgau', label: 'Thurgau' },
  { value: 'Ticino', label: 'Ticino' },
  { value: 'Uri', label: 'Uri' },
  { value: 'Valais', label: 'Valais' },
  { value: 'Vaud', label: 'Vaud' },
  { value: 'Zug', label: 'Zug' },
  { value: 'Zurich', label: 'Zurich' }
]

const languageOptions = [
  { value: 'German', label: 'German'},
  { value: 'English', label: 'English'},
  { value: 'French', label: 'French'},
  { value: 'Italian', label: 'Italian'}
]

const allocationOptions = [
  { value: 'stay-in', label: 'Stay in (Permanent)' },
  { value: 'casual', label: 'Casual' },
]

const skillOptions = [
  { value: 'Compassion and empathy', label: 'Compassion and empathy' },
  { value: 'Communication', label: 'Communication' },
  { value: 'Patience', label: 'Patience' },
  { value: 'Adaptability', label: 'Adaptability' },
  { value: 'Dependability and reliability', label: 'Dependability and reliability' },
  { value: 'Attention to detail', label: 'Attention to detail' },
  { value: 'Time management', label: 'Time management' },
  { value: 'Problem-solving', label: 'Problem-solving' },
  { value: 'Physical stamina', label: 'Physical stamina' },
  { value: 'Self-care', label: 'Self-care' }
]

const uniOptions = [
  { value: 'Harvard University', label: 'Harvard University' },
  { value: 'Stanford University', label: 'Stanford University' },
  { value: 'Massachusetts Institute of Technology (MIT)', label: 'Massachusetts Institute of Technology (MIT)' },
  { value: 'University of Oxford', label: 'University of Oxford' },
  { value: 'University of Cambridge', label: 'University of Cambridge' },
  { value: 'Yale University', label: 'Yale University' },
  { value: 'California Institute of Technology (Caltech)', label: 'California Institute of Technology (Caltech)' },
  { value: 'Princeton University', label: 'Princeton University' },
  { value: 'University of California, Berkeley', label: 'University of California, Berkeley' },
  { value: 'University of Chicago', label: 'University of Chicago' }
]

const levelOptions = [
  { value: 'Fluent', label: 'Fluent' },
  { value: 'Intermediate', label: 'Intermediate' },
  { value: 'Beginner', label: 'Beginner' },
]

export default function ProfileForm() {
  const [user, setUser] = useState({
    basic: {
      name: '',
      date: new Date(),
      intro: '',
    },
    service: {
      location: "",
      allocation: '',
      pricing: ''
    },
    intro: {
      summary: '',
      detailed: '',
      smoking: '',
      driving: '',
      hasCar: '',
      noGoes: '',
      available: '',
      availability: []
    },
    skills: [],
    assessment: ''
  });

  const [qualFormIsVisible, setQualFormIsVisible] = useState(false);
  const [profilePictures, setProfilePictures] = useState([])

  const { getRootProps: getRootPictureProps, getInputProps: getInputPictureProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      let tempArray = [];
      acceptedFiles.forEach((file) => tempArray.push(URL.createObjectURL(file)));
      profilePictures.length + tempArray.length <= 7 && setProfilePictures([...profilePictures, ...tempArray]);

    },
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg', '.jpg'],
    },
    disabled: profilePictures.length >= 7,
  });

  const { getRootProps: getRootPdfProps, getInputProps: getInputPdfProps } = useDropzone({
    onDrop: (acceptedFile) => {
      console.log(acceptedFile[0]);
      setUser({...user, assessment: acceptedFile[0].name});
    },
    accept: {
      'application/pdf': ['.pdf'],
    }
  });

  return (
    <div className="custom-form container">
      <div className="row justify-content-center">
        <div className="col-12">
          <h2>Create user profile</h2>
          <div className="form-wrap">
            <form action="#">
              <div className="row row-cols-1 row-cols-lg-2">
                  <div className="col">
                    <h5>Basic information</h5>
                    <label>Name</label>
                    <input className="info-details col-12" type="text" value={user.basic.name}
                           onChange={(e) => setUser({...user, basic: {...user.basic, name: e.target.value}})}/>
                    <label>Date of birth</label>
                    <DatePicker selected={user.basic.date}
                                onChange={(date) => setUser({...user, basic: {...user.basic, date: date}})}
                                calendarIconClassName="calendar-icon" icon="fa-solid fa-calendar-days" showIcon
                    />
                    <h5>Qualifications in the last 10 years</h5>
                    {!qualFormIsVisible && <button type="button" className="default-btn style-2 d-block mb-4" style={{ padding: '0 7px' }} onClick={() => setQualFormIsVisible(true)} ><span style={{ fontSize: '2rem', lineHeight: '2rem' }}>+</span></button>}
                    {qualFormIsVisible && <form action="#">
                      <label>Institution</label>
                      <CreatableSelect options={uniOptions} isClearable styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          padding: '10px',
                          marginBottom: '20px',
                          border: '1px solid #2133661a'
                        }),
                      }} name='institution'/>
                      <label>Specialty</label>
                      <input type="text" />
                      <label>Duration</label>
                      <div className="row">
                        <div className="col-6">
                          <DatePicker calendarIconClassName="calendar-icon" icon="fa-solid fa-calendar-days" showIcon placeholder="Start date"/>
                        </div>
                        <div className="col-6">
                          <DatePicker calendarIconClassName="calendar-icon" icon="fa-solid fa-calendar-days" showIcon placeholder="End date"/>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between mb-4">
                        <button type="submit" className="default-btn style-2 d-block" onClick={(e) => e.preventDefault()}><span>Add</span></button>
                        <button type="submit" className="default-btn reverse d-block" onClick={() => setQualFormIsVisible(false)}><span>Cancel</span></button>
                      </div>
                    </form>}
                    <h5>Profile photos</h5>
                        <div className="profile-pic-dropzone">
                          <div {...getRootPictureProps( {className: 'dropzone'})}>
                            <input {...getInputPictureProps()} />
                            <p>Drag 'n' drop some files here, or click to select files</p>
                            <em>(Only *.png, *.jpeg or *.jpg files will be accepted)</em>
                          </div>
                          <DragDropContext onDragEnd={(result) => {
                            const {destination, source} = result;

                            if (!destination) {
                              return;
                            }

                            if (
                              destination.droppableId === source.droppableId &&
                              destination.index === source.index
                            ) {
                              return;
                            }

                            const newProfilePics = [...profilePictures];
                            newProfilePics.splice(source.index, 1);
                            newProfilePics.splice(destination.index, 0, profilePictures[source.index]);
                            setProfilePictures(newProfilePics);
                          }}>
                            <Droppable droppableId="profile-droppable">
                              {(provided) => (
                                <div className="img-list row row-cols-1 align-items-center" ref={provided.innerRef} {...provided.droppableProps}>
                                  {profilePictures.map((pic, index) => <div className="col img-wrap" key={index}>
                                    <Draggable draggableId={`draggable-${index}`} index={index}>
                                      {(provided) => (
                                        <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                          <i className="fa-solid fa-xmark" onClick={() => setProfilePictures(profilePictures.filter((_, i) => i !== index))}></i>
                                          <img src={pic} alt={`Profile-${index}`}/>
                                        </div>
                                      )}
                                    </Draggable>
                                  </div>)}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                        </div>
                    <h5>Introduction</h5>
                    <label>Summary</label>
                    <input className="info-details col-12" type="text" value={user.intro.summary}
                           onChange={(e) => setUser({...user, intro: {...user.intro, summary: e.target.value}})}/>
                    <label>Detailed</label>
                    <textarea value={user.intro.detailed}
                              onChange={(e) => setUser({...user, intro: {...user.intro, detailed: e.target.value}})}/>
                    <label>Smoking</label>
                    <div className="row">
                      <div className="col-6">
                        <label className="input-list">
                          <input type="radio" name="smoking"/>
                          <span>Yes</span>
                        </label>
                      </div>
                      <div className="col-6">
                        <label className="input-list">
                          <input type="radio" name="smoking"/>
                          <span>No</span>
                        </label>
                      </div>
                    </div>
                    <label>Driving license</label>
                    <div className="row">
                      <div className="col-6">
                        <label className="input-list">
                          <input type="radio" value="yes" checked={user.intro.driving === 'yes'} name="driving" onChange={(e) => setUser({...user, intro: {...user.intro, driving: e.target.value}})}/>
                          <span>Yes</span>
                        </label>
                      </div>
                      <div className="col-6">
                        <label className="input-list">
                          <input type="radio" value="no" checked={user.intro.driving === 'no'} name="driving" onChange={(e) => setUser({...user, intro: {...user.intro, driving: e.target.value}})}/>
                          <span>No</span>
                        </label>
                      </div>
                    </div>
                    {user.intro.driving === 'yes' && <>
                      <div className="row">
                        <div className="col-6">
                          <label className="input-list">
                            <input type="radio" value="yes" checked={user.intro.hasCar === 'yes'} name="hasCar" onChange={(e) => setUser({...user, intro: {...user.intro, hasCar: e.target.value}})}/>
                            <span>I have my own car</span>
                          </label>
                        </div>
                        <div className="col-6">
                          <label className="input-list">
                            <input type="radio" value="no" checked={user.intro.hasCar === 'no'} name="hasCar" onChange={(e) => setUser({...user, intro: {...user.intro, hasCar: e.target.value}})}/>
                            <span>I don't have a car</span>
                          </label>
                        </div>
                      </div>
                    </>}
                    <label>No-goes</label>
                    <textarea value={user.intro.noGoes}
                              onChange={(e) => setUser({...user, intro: {...user.intro, noGoes: e.target.value}})}/>
                    <label>Available</label>
                    <div className="row">
                      <div className="col-6">
                        <label className="input-list">
                          <input type="radio" value="yes" checked={user.intro.available === 'yes'} name="available" onChange={(e) => setUser({...user, intro: {...user.intro, available: e.target.value}})}/>
                          <span>Yes</span>
                        </label>
                      </div>
                      <div className="col-6">
                        <label className="input-list">
                          <input type="radio" value="no" checked={user.intro.available === 'no'} name="available" onChange={(e) => setUser({...user, intro: {...user.intro, available: e.target.value}})}/>
                          <span>No</span>
                        </label>
                      </div>
                    </div>
                    {user.intro.available === 'yes' && <DatePicker multiple value={user.intro.availability} onChange={(val) => setUser({...user, intro: {...user.intro, availability: val}})} placeholder="Availability"/>}
                  </div>
                  <div className="col">
                    <h5>Service details</h5>
                    <label>Allocation</label>
                    <Select options={allocationOptions} onChange={(val) => setUser({...user, service: {...user.service, allocation: val}})} styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        padding: '10px',
                        marginBottom: '20px',
                        border: '1px solid #2133661a'
                      }),
                    }} name='allocation'/>
                      <label>Location</label>
                      <Select options={locationOptions} isDisabled={user.service.allocation.value === 'stay-in'} styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          padding: '10px',
                          marginBottom: '20px',
                          border: '1px solid #2133661a'
                        }),
                      }} name='location'/>
                    <label>Languages</label>
                    <div className="row align-items-center">
                      <div className="col-12 col-md">
                        <Select options={languageOptions} styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            padding: '10px',
                            marginBottom: '20px',
                            border: '1px solid #2133661a'
                          }),
                        }} name='language'/>
                      </div>
                      <div className="col-12 col-md">
                        <Select options={levelOptions} styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            padding: '10px',
                            marginBottom: '20px',
                            border: '1px solid #2133661a'
                          }),
                        }} name='language_level'/>
                      </div>
                      <div className="col-2">
                        <button type="button" className="default-btn style-2" style={{ marginBottom: '20px', padding: '21px 25px' }}><span>Add</span></button>
                      </div>
                    </div>
                    <label>Pricing ({user.service.allocation.value === 'casual' ? 'per hours' : 'per month'})</label>
                    <div className="input-group">
                      <input type="number" value={user.service.pricing} aria-describedby="currency" onChange={(e) => setUser({...user, service: {...user.service, pricing: e.target.value}})}/>
                      <span className="input-group-text" id="currency">CHF</span>
                    </div>
                    <h5>Skills</h5>
                    <label>Skills</label>
                    <Select
                      options={skillOptions}
                      isMulti
                      classNamePrefix="react-select"
                      isOptionDisabled={() => user.skills.length >=5}
                      value={user.skills}
                      onChange={(val) => setUser({...user, skills: val})}
                      styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        padding: '10px',
                        marginBottom: '20px',
                        border: '1px solid #2133661a'
                      }),
                    }} name='skills'/>
                    <h5>Assessment</h5>
                        <div {...getRootPdfProps({className: 'dropzone'})}>
                            <input {...getInputPdfProps()} />
                            <p>Drag 'n' drop the file here, or click to select file</p>
                            <em>(Only *.pdf file will be accepted)</em>
                        </div>
                    <div className="d-flex">
                      {user.assessment !== '' && <div className="d-flex align-items-baseline gap-1">
                        <i className="fa-solid fa-file-pdf" style={{ fontSize: '17px'}}></i>
                        <span>{user.assessment}</span>
                        <i className="fa-solid fa-xmark remove-pdf" style={{ marginLeft: '10px'}} onClick={() => setUser({...user, assessment: ''})}></i>
                      </div>}
                      <button type="submit" className="default-btn style-2 d-block" style={{ marginLeft: 'auto' }}><span>Submit</span></button>
                    </div>
                  </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}