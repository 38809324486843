import {useState} from "react";
import ProfileForm from "../../component/custom/profileForm";
import HeaderTwo from "../../component/layout/headertwo";
import Header from "../../component/layout/header";

export default function RestrictedProfileForm() {
  return <>
    <Header noAuth/>
    <ProfileForm/>
  </>
}