import { Component } from "react";

class GoogleMap extends Component {
    render() { 
        return (
            <div id="map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d675.630558009441!2d8.548898!3d47.362725!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479aa74a197391c5%3A0x71fed3bd3a1f8a09!2sGoldst%C3%BCck%20AG!5e0!3m2!1sde!2sus!4v1708006238032!5m2!1sde!2sus" width="600" height="450" style={{ border:0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
        );
    }
}
 
export default GoogleMap;