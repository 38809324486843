import { Component } from "react";
import { Link } from "react-router-dom";
import SelectionBox from "../custom/selectionBox";

let selectionData = [
    {
        imgUrl: 'assets/images/work/09.png',
        imgAlt: 'Dating Thumb',
        title: 'Trust And Safety',
        desc: 'Choose from one of our membership levels and unlock features you need.',
        btnText: 'See More Details',
        btnLink: '/policy',
    },
    {
        imgUrl: 'assets/images/work/10.png',
        imgAlt: 'Dating Thumb',
        title: 'Simple Membership',
        desc: 'Choose from one of our membership levels and unlock features you need.',
        btnText: 'Membership Details',
        btnLink: '/membership',
    },
]

function WorkSectionTwo() {
        return (
            <div className="work work--style2 padding-top padding-bottom bg_img" style={{backgroundImage: "url(/assets/images/bg-img/01.jpg)"}}>
                <div className="container">
                    <div className="section__wrapper">
                        <div className="row g-4 justify-content-center">
                            {selectionData.map(({imgUrl, imgAlt, title, desc, btnText, btnLink}, i) => (
                                <SelectionBox key={i} imgUrl={imgUrl} imgAlt={imgAlt} title={title} desc={desc} btnText={btnText} btnLink={btnLink} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
}
 
export default WorkSectionTwo;