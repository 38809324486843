import React, { Component } from "react";
import Select from "react-select";

const title = "Filter your search";
const labelchangetwo = "Gender";
const labelchangethree = "Allocation";
const labelchangefour = "Region";
const labelchangefive = "Language";
const searchBtnText = "Search";

const genderOptions = [
    { value: 'all', label: 'All genders' },
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' },
]

const regionOptions = [
    { value: 'Aargau', label: 'Aargau' },
    { value: 'Appenzell Ausserrhoden', label: 'Appenzell Ausserrhoden' },
    { value: 'Appenzell Innerrhoden', label: 'Appenzell Innerrhoden' },
    { value: 'Basel-Landschaft', label: 'Basel-Landschaft' },
    { value: 'Basel-Stadt', label: 'Basel-Stadt' },
    { value: 'Bern', label: 'Bern' },
    { value: 'Fribourg', label: 'Fribourg' },
    { value: 'Geneva', label: 'Geneva' },
    { value: 'Glarus', label: 'Glarus' },
    { value: 'Graubünden', label: 'Graubünden' },
    { value: 'Jura', label: 'Jura' },
    { value: 'Lucerne', label: 'Lucerne' },
    { value: 'Neuchâtel', label: 'Neuchâtel' },
    { value: 'Nidwalden', label: 'Nidwalden' },
    { value: 'Obwalden', label: 'Obwalden' },
    { value: 'Schaffhausen', label: 'Schaffhausen' },
    { value: 'Schwyz', label: 'Schwyz' },
    { value: 'Solothurn', label: 'Solothurn' },
    { value: 'St. Gallen', label: 'St. Gallen' },
    { value: 'Thurgau', label: 'Thurgau' },
    { value: 'Ticino', label: 'Ticino' },
    { value: 'Uri', label: 'Uri' },
    { value: 'Valais', label: 'Valais' },
    { value: 'Vaud', label: 'Vaud' },
    { value: 'Zug', label: 'Zug' },
    { value: 'Zurich', label: 'Zurich' }
]

const languageOptions = [
    { value: 'German', label: 'German' },
    { value: 'English', label: 'English' },
    { value: 'French', label: 'French' },
    { value: 'Italian', label: 'Italian' }
]

const allocationOptions = [
    { value: 'stay-in', label: 'Stay in (Permanent)' },
    { value: 'casual', label: 'Casual' },
]

class ModalSearch extends Component {
    render() { 
        return (
            <div className="modal-content border-0 mb-4">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel1">{title}</h5>
                </div>
                <div className="modal-body">
                    <form action="#">
                        <div className="banner__list">
                            <div className="row align-items-center row-cols-1">
                                <div className="col">
                                    <label>{labelchangethree}</label>
                                    <div className="row">
                                        <div className="col-12">
                                            <Select options={allocationOptions} styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    padding: '8px',
                                                    marginBottom: '20px',
                                                    border: '1px solid #2133661a'
                                                }),
                                            }} name='allocation'/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <label>{labelchangetwo}</label>
                                    <div className="row">
                                        <div className="col-12">
                                            <Select options={genderOptions} styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    padding: '8px',
                                                    marginBottom: '20px',
                                                    border: '1px solid #2133661a'
                                                }),
                                            }} name='gender'/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <label>{labelchangefour}</label>
                                    <div className="row">
                                        <div className="col-12">
                                            <Select options={regionOptions} isMulti classNamePrefix="react-select" styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    padding: '8px',
                                                    marginBottom: '20px',
                                                    border: '1px solid #2133661a'
                                                }),
                                            }} name='region'/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <label>{labelchangefive}</label>
                                    <div className="row">
                                        <div className="col-12">
                                            <Select options={languageOptions} isMulti classNamePrefix="react-select" styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    padding: '8px',
                                                    marginBottom: '20px',
                                                    border: '1px solid #2133661a'
                                                }),
                                            }} name='language'/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <button type="submit" className="default-btn d-block w-100"><span>{searchBtnText} </span></button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
 
export default ModalSearch;